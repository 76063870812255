import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";
export default class AppareilManager {
  static listUnfixedAppareil(entreprise_id, token = null) {
    return new Promise(function (resolve, reject) {
      new ApiServiceD()
        .getList("appareils/fixed/", { entreprise: entreprise_id }, token)
        .then((response) => {
          store.dispatch("appareils/fetchListAppareil", response.data);
          resolve(response)
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static deleteAppariel(id_appareil, token=null){
    return new Promise(function(resolve, reject){
        new ApiServiceD().delete('appareils/register/',{id: id_appareil}, token)
        .then((resp)=>{
            resolve(resp);
        }).catch((err)=>{reject(err);})
    })
}
}
