<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Bilan des maintenances' : 'Maintenance report' }}</v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Bilan des maintenances' : 'Maintenance report' }}</v-toolbar-title>
            </template>
            <template v-slot:cbody>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn v-appcolor text @click="find">
                        {{ $t('buttons.display') }}
                    </v-btn>
                </v-row>
                <v-switch v-model="toggleSwitch" 
                :label="lang == 'fr' ? 'Chercher entre de dates' : 'Search between dates'" color="#114E60" hide-details>
                </v-switch>
                <v-row>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker @selectedDate="setFromDate" />
                    </v-col>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker2 @selectedDate="setToDate" />
                    </v-col>
                    <v-col cols="12" v-if="!toggleSwitch">
                      <DatePiker @selectedDate="setFindExactDate" />
                    </v-col>
                    
                </v-row>
                <v-card-title class="mt-n8" style="color: #114E60;">
                    {{ lang == 'fr' ? 'Recette:' : 'Profit:' }}
                    <span class="ml-10" v-size="20" style="font-weight: bold;">
                        <v-progress-circular v-if="progress" indeterminate color="primary"></v-progress-circular>
                        {{totalMaint |localFormat}}{{ getEntreprise().type_monaie }}
                    </span>
                </v-card-title>
                <v-card-title class="mt-n8" style="color: #114E60;">
                    {{ lang == 'fr' ? 'Bénéfice:' : 'Income:' }}
                    <span class="ml-8" v-size="20" style="font-weight: bold;">
                        <v-progress-circular v-if="progress" indeterminate color="primary"></v-progress-circular>
                        {{totalIncome|localFormat}}{{ getEntreprise().type_monaie }}
                    </span>
                </v-card-title>
                <v-row>
                    
                </v-row>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import DatePiker from '../DatePiker.vue';
import DatePiker2 from '../DatePiker2.vue';
import moment from 'moment';
import {mapGetters} from 'vuex'
export default {
    components: { FormTemplate, DatePiker, DatePiker2 },
    data:() =>({
        toggleSwitch: false,
        fromDate: null,
        toDate: null,
        exactDate: null,
        totalMaint: 0,
        totalIncome: 0,
        listeMaint:[],
        progress: true,
    }),
    mounted() {
        setTimeout(() => {
            this.initialize() 
            this.progress = false
        }, 1500);
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        ...mapGetters("comptable",['getListMaintenance','getSoldeMaintenance','getIncomeMaintenance']),
        initialize(){
            this.listeMaint = this.getListMaintenance()
            this.totalMaint = this.getSoldeMaintenance()
            this.totalIncome = this.getIncomeMaintenance()
            this.emittotal(this.totalMaint)
        },
        find(){
            this.totalMaint = 0
            this.totalIncome = 0
            if(!this.toggleSwitch){
                this.listeMaint.forEach(el => {
                    if (moment(el.registred_at).isSame(this.exactDate) ){
                        this.totalMaint += parseFloat(el.total_payer)
                        if(parseFloat(el.prix_pieces) > 0){
                            this.totalIncome += (parseFloat(el.total_apayer) - parseFloat(el.prix_pieces))
                        }
                    }
                });
                this.emittotal(this.totalMaint)
            }else{
                this.listeMaint.forEach(el => {
                    if (moment(el.registred_at).isBetween(this.fromDate, this.toDate) ){
                        this.totalMaint += parseFloat(el.total_payer)
                        if(parseFloat(el.prix_pieces) > 0){
                            this.totalIncome += (parseFloat(el.total_apayer) - parseFloat(el.prix_pieces))
                        }
                    }
                });
                this.emittotal(this.totalMaint)
            }
        },
        emittotal(a){
            this.$emit('totalVente',a)
        },
        setFindExactDate(val) {
            this.exactDate = val
        },
        setToDate(val) {
            this.toDate = val
        },
        setFromDate(val) {
            this.fromDate = val
        },
    },
}
</script>
<style lang="">
    
</style>