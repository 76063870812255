<template>
    <nav>
        <v-app-bar v-appcolor dark app class="line " height="80px" elevation="2">
            <v-btn icon @click.stop="toggleDrawer" rounded color="#F0EBE3">
                <v-icon size="30px">fa-duotone fa-bars</v-icon>
            </v-btn>
            <span id="app-name">{{ entrepriseName }}</span>
            <!-- Plan suscription -->
            <span id="alert-suscription" v-if="nombre_de_jour_de_suscription_restant <= 11">
                &nbsp; {{ lang == 'fr' ? "Votre de jours d'abonnement restant" : "Your remaining subscription days" }}
                : {{ nombre_de_jour_de_suscription_restant }}
                <v-btn variant="outlined" @click="showSuscriptionPlan">
                    penser à reactier
                </v-btn>
            </span>
            <!-- Fin Plan suscription -->
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <!-- Alert info -->
            <v-alert v-if="errors.message" dense :type="errors.type"
                style="height: 60px; z-index: 9999; position: fixed; max-width=100%;" elevation="20">
                {{ errors.message }}
            </v-alert>
            <!-- End alert -->
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <!-- <v-spacer></v-spacer> -->
            <v-menu transition="slide-y-transition" bottom>
                <template v-slot:activator="{ on, attrs }">

                    <v-icon color="white"></v-icon>

                    <v-avatar fab color="cyan" elevation="0" class="mr-2 ml-3 d-sm-flex" size="30px" v-bind="attrs"
                        v-on="on">
                        <v-img src="../assets/avatar.png"></v-img>
                    </v-avatar>
                    <span class="d-none d-sm-flex">{{ completName }}</span>
                </template>
                <v-list color="blue lighten-5" class="mt-6">

                    <v-list-item-group v-model="selectedItem">

                        <v-list-item v-for="(item, i) in profiles" :key="i" :to="item.link">
                            <v-list-item-icon>
                                <v-icon color="black" size="20px">{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content class="text-list ml-n4 black--text">
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>

                    </v-list-item-group>
                </v-list>
            </v-menu>
            <!-- Write a note -->
            <v-btn fab height="9px" :title="lang == 'fr' ? 'Créer une note' : 'Create a note'" width="9px"
                color="transparent" elevation="0" class="mr-3 ml-3" to="/notebook">
                <v-icon color="white">fas fa-notes-medical</v-icon>
            </v-btn>
            <!-- End note -->
            <!-- Menu dette a payer -->
            <v-btn fab height="9px" width="9px" color="transparent" elevation="0" class="mr-8 ml-3"
                @mousedown="switchDialogDette">
                <v-icon color="white">fas fa-bell</v-icon>
                <v-badge color="red" :content="totalInPayer" v-if="totalInPayer > 0" class="ml-n2"></v-badge>
            </v-btn>
            <DialogDetteView @payer="(item) => lounchPayer(item)" :show-dialog="dialogDette" />
            <!-- Ende -->
        </v-app-bar>
        <!-- Navigation drawer -->

        <v-navigation-drawer :width="210" :style="mini ? 'padding:5px' : 'padding:15px'" v-model="drawer" v-appcolor
            dark app :permanent="perm" :mini-variant="mini" mini-variant-width="110">

            <v-list>
                <div style="border-bottom: 2px solid white;" class="mb-2">
                    <v-img v-if="!mini" src="../assets/logo/logo1.png" class="mx-auto mb-1" height="80" dark contain>
                    </v-img>
                    <v-img v-if="mini" src="../assets/logo/logo2.png" class="mx-auto mb-2" height="60" contain>
                    </v-img>
                </div>

                <v-list-item-group v-model="selectedItem" active-class="active">

                    <v-list-item v-for="(item, i) in accessMenu" :key="i" :to="item.link">

                        <v-list-item-content align="center" :class="mini ? 'fa-3x' : 'd-none'">
                            <v-icon v-text="item.icon" class="mb-3" align="center" :size="mini ? '35px' : ''"
                                :style="mini ? '--fa-beat-fade-opacity: 0.67; --fa-beat-fade-scale: 1.060;' : ''">
                            </v-icon>
                            <v-list-item-subtitle v-text="item.text" align="center" class="caption subtitle">
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-icon :class="mini ? 'd-none' : ''">
                            <v-icon :color="item.active ? 'dark lighten-2' : 'whitesmoke'"
                                :size="mini ? '35px' : '30px'">{{
                                    item.icon
                                }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content :class="mini ? 'd-none' : 'text-list'">
                            <v-list-item-title v-text="item.text"></v-list-item-title>
                        </v-list-item-content>

                    </v-list-item>

                </v-list-item-group>

            </v-list>
        </v-navigation-drawer>
        <!-- Ende -->
        <!-- Dialog payer dette -->
        <div class="text-center">
            <v-dialog v-model="dialogPayer" width="500" persistent>

                <v-card>
                    <v-card-title class="text-h5" v-appcolor>
                        Remboursement de dette
                    </v-card-title>

                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field v-model="formMaintenance.rest_apayer" label="Saisir le montant"
                                :rules="requireRule" required>
                            </v-text-field>
                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="payerDette">
                            Enregister
                        </v-btn>
                        <v-btn color="primary" text @click="close">
                            Fermer
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
        <!-- Ende -->
        <DialogPanSuscriptionView />
    </nav>
</template>

<script>
import DialogDetteView from "./DialogDetteView.vue";
import DialogPanSuscriptionView from "./DialogPanSuscriptionView.vue"
import SoldeCaisse from "@/services/caisse/SoldeCaisse.js";
import { mapGetters } from "vuex";
import DetteManager from "@/services/dettes/DetteManager";
import { getSupportedLocales } from "@/util/i18n/supported-locales"
export default {
    components: { DialogDetteView, DialogPanSuscriptionView },
    data() {
        return {
            // Sidebar
            drawer: false,
            dialogDette: false,
            mini: false,
            perm: true,
            errors: this.getErrors(),
            locales: getSupportedLocales(),
            width: window.innerWidth,
            mobilesearch: false,
            token: localStorage.getItem('accessToken'),
            nombre_de_jour_de_suscription_restant: "",
            // Ende sidebar
            dialogPayer: false,
            selectedItem: 0,
            // menu: true,
            listNotification: [],
            totalInPayer: '',
            dialogSetting: false,//Declancher le dialog règlage
            dialogLoadMaint: false,//Declancher le chargement des dettes de maintenance
            valid: false,//Form validation
            formMaintenance: {
                rest_apayer: '',
                type: '',
            },
            detteMaintenanceId: '',// Dette de maintenane a payer
            items: [
                { id: 1, text: "Accueil", icon: "fa-brands fa-accusoft", link: "/" },
                { id: 2, text: "Services", icon: "fa-duotone fa-briefcase", link: "/admin/service/vente" },
                { id: 3, text: "Produits", icon: "fa-duotone fa-layer-group", link: "/admin/pieces/articles" },
                { id: 4, text: "Stocke", icon: "fa-duotone fa-chart-column", link: "/admin/stockes/aprovisionnement" },
                { id: 5, text: "Comptabilité", icon: "fa-duotone fa-coins", link: "/admin/comptabilite" },
                { id: 6, text: "Paramettre", icon: "fa-solid fa-gears", link: "/setting" },
            ],
            enItems: [
                { id: 1, text: "Home" },
                { id: 2, text: "Services" },
                { id: 3, text: "Products" },
                { id: 4, text: "Stock" },
                { id: 5, text: "Accounting" },
                { id: 6, text: "Setting" }
            ],
            profiles: [
                { text: "Profile", icon: "fa-solid fa-user", link: "/profil" },
                { text: "Déconnexion", icon: "fa-duotone fa-right-from-bracket", link: "/logout" },
            ],
            completName: '',
            entrepriseName: '',

            requireRule: [
                v => !!v || 'Ce champ est obligatoire',
            ],
            accessMenu: [],
            // accessURL:'',
            userComponent: [],
        }
    },
    computed: {
        userIsMaster: () => this.isMaster(),
        getTotalDette() {
            return this.maintUpdated()
        },
        lang() {
            return this.$store.state.locale
        },
    },
    watch: {
        width(val) {
            if (val <= 1200) {
                this.perm = false;
            }
        },
        getTotalDette() {
            this.totalInPayer = parseInt(this.totalDette())
        }
    },
    created() {
        window.addEventListener("resize", this.onResize);
        this.initialize()
        setTimeout(() => {
            this.totalInPayer = parseInt(this.totalDette())
        }, 900);
        for (var i = 0; i < this.items.length; i++) {
            this.items[i].state = this.isMaster()
        }
        this.userComponent = this.getUserComponents()
        this.controlMenu()
        this.completName = this.getUser().first_name
        this.entrepriseName = this.getUser().entreprise.name
        this.nombre_de_jour_de_suscription_restant = this.getUser().sus_info['remindingDuretion']

    },

    mounted() {
        this.onResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
    methods: {
        ...mapGetters(['getErrors',
            'getAccessToken',
            'isMaster',
            'getUserComponents',
            'getEntreprise',
            'getUser', 'getMenuItemIsActive',]),
        ...mapGetters("dettes", ['totalDette', 'maintUpdated']),

        controlMenu() {
            this.accessMenu = []
            console.log("He isMaster: ", this.isMaster())
            // this.items[0].active = this.getMenuItemIsActive();
            if (this.isMaster() == true) {
                if (this.$store.state.locale == 'en') {
                    for (let i = 0; i < this.items.length; i++) {
                        this.items[i].text = this.enItems[i].text
                    }
                }
                this.accessMenu = this.items
            } else {
                var menu = []
                // this.accessMenu.push(this.items[0])
                this.items.map((elem) => {
                    if (this.userComponent.some(comp => (comp.libelle).toLowerCase() == (elem.text.toLowerCase()))) {
                        menu.push(elem)
                    }
                })
                //On recupère dans les items la liste de components associés à l'utilisateur
                menu.unshift(this.items[0])
                menu.forEach(el => this.items.text == el.text);

                if (this.$store.state.locale == 'en') {
                    //Pui on recupère leur correspondant lans liste des items de l'anglais

                    for (let i = 0; i < menu.length; i++) {

                        for (let j = 0; j < this.enItems.length; j++) {

                            if (menu[i].id == this.enItems[j].id) {

                                menu[i].text = this.enItems[j].text;

                            }

                        }

                    }

                }

                this.accessMenu = menu
            }
        },
        switchDialogDette() {
            this.$store.dispatch('dettes/switchDialogDette', true);
        },
        showSuscriptionPlan() {
            this.$store.dispatch('entreprise/switchDialogEntreprise', true);
        },
        onResize() {
            this.width = window.innerWidth;
            // this.height = window.innerHeight;
            if (this.width <= 1200) {
                this.perm = false;
            }
        },
        toggleDrawer() {
            if (this.width <= 1200) {
                this.drawer = !this.drawer;
            }
            else {
                this.mini = !this.mini;
            }
        },
        initCaissse() {
            SoldeCaisse.initializeCaisse(this.getAccessToken())
        },
        initialize() {
            DetteManager.listDette(this.getEntreprise().id, this.getAccessToken())
        },
        lounchSetting() {
            this.dialogSetting = true
        },
        lounchPayer(item) {
            console.log(item)
            this.detteMaintenanceId = item.id
            this.formMaintenance.type = item.type
            console.log("this.detteMaintenance: ", this.detteMaintenanceId)
            this.dialogPayer = true
        },
        payerDette() {
            if (this.$refs.form.validate()) {
                DetteManager.paydebt(this.formMaintenance, this.detteMaintenanceId, this.getAccessToken())
                    .then(() => {
                        this.dialogLoadMaint = !this.dialogLoadMaint
                        this.initialize()
                        this.totalInPayer = parseInt(this.totalDette())
                        this.successMessage("Dette payer avec succès")
                        this.close()
                    })

            }
        },
        successMessage(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                timeout: 4095,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        close() {
            this.$refs.form.reset()
            this.dialogPayer = false
        },
    },

}
</script>

<style scoped>
@media only screen and (max-width: 600px) {
    body {
        background-color: lightblue;
    }
}

#app-name {
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-family: 'Courier New', Courier, monospace !important;
    font-size: 18px;
}

#alert-suscription {
    font-weight: 600;
    font-family: 'Courier New', Courier, monospace !important;
    font-size: 16px;
    color: blanchedalmond;
}

.active {
    background-color: rgba(48, 140, 148, 0.233);
}

.v-list .v-list-item-group .v-list-item {
    border-bottom: 1px dotted white;
}

.v-list .v-list-item-group .v-list-item:last-child {
    border-bottom: none;
}

.v-list .v-list-item-group .v-list-item-content {
    display: none;
}

.text-list {
    color: whitesmoke;
    text-align: left;
    font-size: medium;
    letter-spacing: 1px;
    margin-left: 15px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-weight: lighter;
}

.subtitle {
    font-size: 10px;
}
</style>