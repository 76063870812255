<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="700px" hide-overlay>
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5 white--text">Details</span>
                </v-card-title>
                <v-card-text>
                    <v-card-title>{{ lang == 'fr'?'Client':'Customer' }}:</v-card-title>
                    <div class="dark--text ms-15 text-info">
                        <p>{{ full_name }}</p>
                        <p>{{ lang == 'fr'?'Téléphone':'Phone number' }}: {{ tel }}</p>
                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title>{{ lang == 'fr'?'Appareil':'Divice' }}:</v-card-title>
                    <div class="dark--text ms-15 text-info">
                        <p>{{ appareil }} , Ticket: <v-chip>{{ ticket }}</v-chip>
                        </p>
                        <p>{{ lang == 'fr'?'Panne':'Problem' }}: {{ panne }}</p>
                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title>{{ lang == 'fr'?'Pieces utilisées':'Pieces used' }}:</v-card-title>
                    <div class="dark--text ms-15 text-info">
                        <v-list>
                            <v-list-item v-for="(item, i) in pieceList" :key="i">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.libelle }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <p>
                            {{ lang == 'fr'?'Valeur':'Amount' }}: <span class="green--text">
                                {{ prix_pieces | localFormat }}{{ getEntreprise().type_monaie }}
                            </span>&nbsp;
                            {{ $t('service.maintenance.workforce') }}: <span class="green--text">
                                {{ main_eouvre | localFormat }}{{ getEntreprise().type_monaie }}
                            </span>
                        </p>

                    </div>
                    <v-divider class="mx-4"></v-divider>
                    <v-card-title>{{ lang == 'fr'?'Acquittement':'Acquittal' }}:</v-card-title>
                    <div class="dark--text ms-15 text-info">
                        <p>{{ $t('service.maintenance.netToPaid') }}: 
                            <span class="green--text">{{ total_apayer | localFormat }}{{ getEntreprise().type_monaie }}</span>
                        </p>
                        <p></p>
                        <p>
                            {{ $t('service.maintenance.totalPaid') }}: 
                            <span class="green--text">{{ total_payer | localFormat }}{{ getEntreprise().type_monaie }}</span>
                        </p>
                        <p>{{ $t('service.maintenance.totalToPay') }}: 
                            <span class="red--text">{{ reste_apayer | localFormat }}{{ getEntreprise().type_monaie }}</span>
                        </p>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" text @click="dialog = false">
                        {{ $t('buttons.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    name: "DialogMaintenace",
    components: {},
    data: () => ({
        dialog: false,

        appareil: '',
        ticket: '',
        full_name: '',
        tel: '',
        panne: '',
        pieceList: [],
        registred_at: '',
        total_apayer: 0,
        total_payer: 0,
        prix_pieces: 0,
        main_eouvre: 0,
        reste_apayer: '',


    }),
    props: {
        lounchDetail: null,
        detailInfo: null,
    },
    computed:{
        lang(){
            return this.$store.state.locale
        }
    },
    watch: {
        /**
         * a wather to lounch the dialog
         */
        lounchDetail() {
            this.dialog = true;
        },

        detailInfo(data) {
            this.appareil = data.appareil,
            this.ticket = data.ticket,
            this.full_name = data.full_name,
            this.tel = data.tel,
            this.panne = data.panne,
            this.pieceList = data.piece,
            this.registred_at = data.registred_at,
            this.total_apayer = data.total_apayer,
            this.total_payer = data.total_payer,
            this.prix_pieces = data.prix_pieces,
            this.main_eouvre = data.main_eouvre,
            this.reste_apayer = (parseFloat(data.total_apayer) - parseFloat(data.total_payer)),
            console.log(data)
        }

    },
    methods: {
        ...mapGetters(['getEntreprise']),
    }

}
</script>

<style>
.text-info {
    color: darkslategray;
    font-size: 16px;
}
</style>