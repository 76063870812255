<template>
  <v-app>
    <!-- <LoaderViewVue v-if="load"/> -->
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
// import LoaderViewVue from './components/LoaderView.vue';
import { mapGetters } from 'vuex';
export default {
  name: "App",

  data() {
    return {
      token: '',
      load: this.getLoader(),
    }
  },
  // components:{LoaderViewVue},
  created() {
    this.load = this.getLoader()
    this.redirect()
  },
  methods: {
    ...mapGetters(['getLoader', 'isAuthenticated']),
    redirect() {
      if (!this.isAuthenticated()) {
        let path = this.$router.currentRoute.path
        if (path == '/'){
          this.$router.push({ path: '/login' })
        } 
      }
    },
  },

}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
