<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-spacer></v-spacer>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Liste dépense mensuelle' : 'Monthly expenditure list' }} 
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Liste dépense mensuelle' : 'Monthly expenditure list' }} 
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <template v-slot:cbody>

            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="white black--text mb-2" size="15px">
            </v-text-field>
            <v-data-table 
                :headers="headers" 
                :items="listDepenseMensuelle" 
                :search="search" 
                sort-by="calories" class="elevation-1" 
                >
            </v-data-table>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import { mapGetters } from 'vuex';
export default {
    components: { FormTemplate},
    data() {
        return {
            search: '',
            headers: this.$i18n.locale == 'fr' ? [
                {text: 'Montant',align: 'start',sortable: true, value: 'amount'},
                { text: 'Source Financière', value: 'source', align: 'center' },
            ]:[
                {text: 'Amoount',align: 'start',sortable: true, value: 'amount'},
                { text: 'Financial source', value: 'source', align: 'center' },
            ],
            listDepenseMensuelle:[],
        }
    },
    computed: {
        controleDepense(){
            return this.getDepenseUpdated()
        },
        lang() {
            return this.$store.state.locale
        }
    },
    watch: {
        controleDepense(){
            this.initialize()
        }
    },
    created(){
        this.initialize()
    },
    methods: {
        ...mapGetters("comptable",['getDepenseUpdated','getListDepense']), 
        initialize(){
            this.listDepenseMensuelle = this.getListDepense()
        }
    }

}
</script>
<style lang="">
    
</style>