import axios from 'axios'
const network = {
    ip: "bsassistance.com",
    // ip: "127.0.0.1",
    // port: "8000",
    timeout: 6000
}
export default class ApiService {

    axiosInstance() {
        return axios.create({
            baseURL: `https://${network.ip}/`
            // baseURL: `http://${network.ip}:${network.port}/`
        })
    }

    getTimeout() { return network.timeout }
    /**
     * Executes post request
     * @param {String} url
     * @param {object} data
     * @param {Dict} param 
     * @param {String} token 
     */
    add(url, data, param = null, token = null) {
        return this.axiosInstance().post(url, data, {
            param: param,
            headers: {
                Authorization: `Token ${token}`
            }
        })
    }
    /**
     * Execute une requete get sur toutes les données disponibles
     * @param {String} url
     * @param {String} token
     */
    findAll(url, token = null) {
        // console.log("Le token: ", network.token)
        return this.axiosInstance().get(url, {
            headers: {
                Authorization: `Token ${token}`
            }
        })
    }

    /**
     * Exécute une requête de selection par valeur d'id
     * @param {String} url 
     * @param {String} token
     * @param {Integer} id 
     */
    findOne(url, id, token = null) {
        return this.axiosInstance().get(url, {
            params: { id },
            headers: {
                Authorization: `Token ${token}`
            }

        })
    }
    /**
     * Requête de mise à jour
     * @param {String} url
     * @param {String} token 
     * @param {Object} data 
     * @param {Integer} id 
     */
    update(url, data, id, token = null) {
        return this.axiosInstance().put(url, data, {
            params: { id },
            headers: {
                Authorization: `Token ${token}`
            }

        })
    }
    /**
     * 
     * @param {String} url
     * @param {String} token 
     * @param {Integer} id 
     */
    delete(url, id, token = null) {
        return this.axiosInstance().delete(url, {
            params: { id },
            headers: {
                Authorization: `Token ${token}`
            }
        })
    }
    /**
    * Executes post request
    * @param {String} url 
    * @param {object} data 
    */
    login(url, data) {
        return this.axiosInstance().post(url, data, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true"
            }
        })
    }
}