<template>
    <v-app-bar color="transparent" dark dense flat >
        <v-chip class="ma-2 respocl" to="/" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{$i18n.locale == 'fr' ? "Accueil" : "Home"}}
                <v-icon right small color="white">fa-brands fa-accusoft</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px" >fa-brands fa-accusoft</v-icon>
        </v-chip>
        <v-chip class="ma-2 respocl" v-for="(item, i) in  $i18n.locale == 'fr' ? frItems: enItems" :key="i" @click="navigateTo(item.link)" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{ item.text }}
                <v-icon right small color="white">{{ item.icon }}</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px" >{{item.icon}}</v-icon>
        </v-chip>
    </v-app-bar>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
    name: 'ServiceChips',
    data: () => ({
        link: 'maintenance',
        enItems:[
            { text: "Maintenance", icon: "fa-duotone fa-screwdriver-wrench", link: "/admin/service/maintenance" },
            { text: "Customers", icon: "fas fa-users-line", link: "/admin/service/client" },
            { text: "Sales", icon: "fa-duotone fa-hand-holding-dollar", link: "/admin/service/vente" },
        ],
        frItems:[
            { text: "Maintenances", icon: "fa-duotone fa-screwdriver-wrench", link: "/admin/service/maintenance" },
            { text: "Clients", icon: "fas fa-users-line", link: "/admin/service/client" },
            { text: "Ventes", icon: "fa-duotone fa-hand-holding-dollar", link: "/admin/service/vente" },
        ]
    }),
    methods: {
        // ...mapGetters(['getAccessMenu','getUser']),
        navigateTo(chemin) {
            this.$router.push({ path: chemin });
            this.$emit('linkCliked', chemin)
        },
    },
    

}
</script>

<style scoped>
@media only screen and (max-width: 600px) {

    /* For mobile phones: */
    .respocl {
        background-color: transparent !important;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
}
</style>