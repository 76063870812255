<template>
    <v-app style="background:#DDDDDD">
        <SideBare />
        <ToolBare>
            <template v-slot:cbody>
                <StockeChips v-on:linkCliked="makeLinke()" />
            </template>
        </ToolBare>
        <component v-bind:is="cmp"></component>
    </v-app>
</template>
<script>
import SideBare from "../components/SideBare.vue";
import ToolBare from "@/components/ToolBare.vue";
import ListApprovisionnementVue from "@/components/Approvisionnement/ListApprovisionnement.vue";
import ListFournisseurView from "@/components/Fournisseurs/ListFournisseurView.vue"
import StockeChips from "@/components/StockeChips.vue";
export default {
    name: "StockView",
    data: () => ({
        cmp: '',
    }),
    components: {
    SideBare,
    ToolBare,
    StockeChips,
    "aprovisionnement": ListApprovisionnementVue,
    "fournisseur": ListFournisseurView,
},
    methods: {
       makeLinke() {
            this.cmp = window.location.pathname.split('/').pop()
        },
    },
    created(){
        this.makeLinke()
    }
}
</script>
<style>
</style>