<template lang="">
    <div>
       
    </div>
</template>
<script>
export default {
    props:{
        statusCode:null
    },
    watch:{
        statusCode(msg){
            let type    = msg[0]
            let message = msg[1]
            switch(type){
                case 'error':
                    this.errorMessage(message)
                    break;
                case 'success':
                    this.successMessage(message)
                    break;
                
            }
        }
    },
    methods: {
        errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    successMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    },
    
}
</script>
<style lang="">
    
</style>