<template>
  <v-container fluid>
    <v-toolbar flat v-appcolor>
      <v-toolbar-title class="white--text" style="font-size:medium">{{dateJour}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
        rounded class="d-none d-sm-flex  white black--text" style="width:50%; max-width: 50%;">
      </v-text-field>
      <v-btn v-appcolor dark class="mb-2 ml-1 mt-2" :sm="false" @click="newMaintenane">
        {{$t('buttons.addNew')}}
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="d-flex d-sm-none" align="left"
      style="color:cornflowerblue; font-weight:900; font-size:large;font-family:Arial, Helvetica, sans-serif">
      {{ $t('service.maintenance.recetteJour') }}: {{ totalCost | localFormat }}{{ getEntreprise().type_monaie }}
    </div>
    <v-data-table :headers="$i18n.locale =='fr'? frHeaders: enHeaders" :items="listeMaintenanceJours" :search="search" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title class="d-none d-sm-flex">{{ $t('service.maintenance.recetteJour') }}: {{ totalCost | localFormat }}{{ getEntreprise().type_monaie }} </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-flex d-sm-none white black--text" size="15px">
          </v-text-field>
          <v-dialog persistent v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">

              <v-btn v-appcolor dark class="mb-2  d-none d-sm-flex" :sm="false" v-bind="attrs" v-on="on">
                <v-icon class="mx-auto" color="white--text">fa-duotone fa-calendar-days</v-icon>
              </v-btn>
              <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-block d-sm-none" width="40px" height="40px"
                v-bind="attrs" v-on="on">
                <v-icon>fa-duotone fa-calendar-days</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title v-appcolor>
                <span class="text-h5">{{ lang == 'fr' ? frFormTitle : enFormTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker @selectedDate="setFromDate" />
                    </v-col>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker2 @selectedDate="setToDate" />
                    </v-col>
                    <v-col cols="12" v-if="!toggleSwitch">
                      <DatePiker @selectedDate="setFindExactDate" />
                    </v-col>
                  </v-row>

                  <v-switch v-model="toggleSwitch" 
                  :label="lang == 'fr' ? 'Chercher entre de dates' : 'Search between dates'" color="#114E60" hide-details>
                  </v-switch>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="find">
                  {{ $t('buttons.display') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Details">
          fa-light fa-arrow-up-right-from-square
        </v-icon>
      </template>
    </v-data-table>
    <DialogMaintenance :lounchDialog="dialogNewMaitenance" v-on:submit="initialize()"/>
    <DialogDetailMaintenance :lounchDetail="dialogDetailMaitenance" :detailInfo="detailItme"/>
  </v-container>
</template>
<script>
import DatePiker from '../DatePiker.vue'
import DatePiker2 from '../DatePiker2.vue'
import moment from 'moment'
import DialogMaintenance from '../DialogMaintenance.vue'
import DialogDetailMaintenance from '../DialogDetailMaintenance.vue'
import MaintenaceManager from '@/services/MaintenanceManager'
import {mapGetters, mapActions} from 'vuex'
export default {
  components: {
    DatePiker,
    DatePiker2,
    DialogMaintenance,
    DialogDetailMaintenance
},
  data: () => ({
    dialog: false,
    dialogNewMaitenance: false,
    dialogDetailMaitenance: false,
    toggleSwitch: false,
    search: '',
    totalCost: 0,
    Tokents: '',
    frHeaders: [
      {text: 'Prénom(s) et Nom',align: 'start',sortable: true,value: 'full_name',},
      { text: 'Téléphone', value: 'tel' },
      { text: 'Appareil', value: 'appareil' },
      { text: 'Panne', value: 'panne' },
      { text: 'Ticket', value: 'ticket' },
      { text: 'Net a payer', value: 'total_apayer' },
      { text: 'Total payer', value: 'total_payer' },
      { text: 'Reste a payer', value: 'rest_apayer' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    enHeaders: [
      {text: 'First and Last name',align: 'start',sortable: true,value: 'full_name',},
      { text: 'Phone', value: 'tel' },
      { text: 'Device', value: 'appareil' },
      { text: 'Problem', value: 'panne' },
      { text: 'Ticket', value: 'ticket' },
      { text: 'Net to pay', value: 'total_apayer' },
      { text: 'Total paid', value: 'total_payer' },
      { text: 'Remains to pay', value: 'rest_apayer' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    detailItme: null,
    fromDate: null,
    toDate: null,
    exactDate: null,
    dateJour : null,

    tmp_list: [],
    listeMaintenanceJours: [],

    listeMaintenance: [],



    editedIndex: -1,
  }),

  computed: {
    frFormTitle() {
      return this.editedIndex === -1 ? 'Historique' : ''
    },
    enFormTitle() {
      return this.editedIndex === -1 ? 'Historical' : ''
    },
    lang() {
      return this.$store.state.locale
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
    this.initialize()
    this.displayDate(new Date())
  },
  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise']),
    ...mapActions("maintenance", ['fetchlistMaintenance']),
    /**
     * This metho display display today's date sell
     * The data from this request is in this form:
     * {
     *   id: Integer, data: array(), registred_at: date
     * }
     */
    initialize() {
      // let cm = new ApiServiceD()
     MaintenaceManager.listMaintenance(this.getEntreprise().id,this.getAccessToken())
      .then((rep) => {
        console.log("La reponse: ",rep);
        let sauve = [], sauveToday = [];
          this.totalCost = 0
          rep.forEach(el => {
            let registred_at = el.registred_at
            // a local variable store today date
            let todayDate = moment(new Date()).format('YYYY-MM-DD')
            // To put a part today's sell and other sells
            let donne;
            if (moment(todayDate).isSame(registred_at)) {
              this.totalCost += 0
              this.totalCost += parseFloat(el.total_payer)
              donne = {
                id          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                prix_pieces : el.prix_pieces,
                main_eouvre : el.main_eouvre,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: registred_at,
              }
              sauveToday.push(donne)
            } else {
              donne = {
                id          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                prix_pieces : el.prix_pieces,
                main_eouvre : el.main_eouvre,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: registred_at,
              }
            }
            sauve.push(donne)

          });
          this.listeMaintenance = sauve
          this.listeMaintenanceJours = sauveToday
          console.log(this.listeMaintenanceJours)
      }).catch((error) => {console.log(error)});
    },
    /**
     * This method is to search in the list to display sells per date
     */
    find() {
      let sauve = [];
      this.totalCost = 0
      if (this.toggleSwitch == false) {
        this.displayDate(this.exactDate)
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.registred_at).isSame(this.exactDate)) {
              this.totalCost += parseFloat(el.total_payer)
              let donne = {
                id          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                prix_pieces : el.prix_pieces,
                main_eouvre : el.main_eouvre,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: el.registred_at,
              }
              sauve.push(donne)
            }
          });
        } else {
          this.tmp_list = this.listeMaintenance
          this.listeMaintenance.forEach(el => {
            if (moment(el.registred_at).isSame(this.exactDate)) {
              this.totalCost += parseFloat(el.total_payer)
              let donne = {
                id          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                prix_pieces : el.prix_pieces,
                main_eouvre : el.main_eouvre,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: el.registred_at,
              }
              sauve.push(donne)
            }
          });

        }
      } else {
         this.displayDate(new Date())
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.registred_at).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += parseFloat(el.total_payer)
              let donne = {
                rid          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                main_eouvre : el.main_eouvre,
                prix_pieces : el.prix_pieces,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: el.registred_at,
              }
              sauve.push(donne)
            }
            // console.log(el.registred_at)
          });
        } else {
          this.tmp_list = this.listeMaintenance
          this.listeMaintenance.forEach(el => {
            if (moment(el.registred_at).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += parseFloat(el.total_payer)
              console.log('Ok')
              let donne = {
                id          : el.id,
                full_name   : el.full_name,
                tel         : el.tel,
                appareil    : el.appareil,
                ticket      : el.ticket,
                panne       : el.panne,
                piece       : el.piece,
                prix_pieces : el.prix_pieces,
                main_eouvre : el.main_eouvre,
                total_apayer: el.total_apayer,
                total_payer : el.total_payer,
                rest_apayer : el.rest_apayer,
                registred_at: el.registred_at,
              }
              sauve.push(donne)
            }
            // console.log(el.registred_at)
          });

        }
      }
      this.listeMaintenance = sauve
      this.listeMaintenanceJours = sauve
      this.close()
    },

    displayDate(date){
      moment.locale(this.$i18n.locale)
      let todayDate = moment(date).format('LLLL')
      this.dateJour = todayDate
    },
    newMaintenane() {
      this.dialogNewMaitenance = !this.dialogNewMaitenance
    },
    setFromDate(val) {
      this.fromDate = val
    },
    setToDate(val) {
      this.toDate = val
    },
    editItem(item){
      this.dialogDetailMaitenance = !this.dialogDetailMaitenance
      this.detailItme = item
      // console.log(item)
    },
    setFindExactDate(val) {
      this.exactDate = val
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedIndex = -1
      })
    },
  },
}
</script>