// import moment from "moment"
export default{
    namespaced: true,
    state:{
        listEntreprise:[],
        codeEntreprise:'',
        dialogEntreprise: false,
    },
    getters:{
        getListEntreprise:(state) => state.listEntreprise,
        getCodeEntreprise:(state) => state.codeEntreprise,
        getDialogEntreprise:(state) => state.dialogEntreprise
    },
    mutations:{  
        SET_CODE_ENTREPRISE:(state, code) => state.codeEntreprise = code,
        SET_LIST_ENTREPRISE:(state, lists) => state.listEntreprise = lists,
        SET_DIALOG_Entreprise:(state, value) => state.dialogEntreprise = value
    },
    actions:{
        async assignCodeEntreprise({commit}, payload){
            commit('SET_CODE_ENTREPRISE',payload)
        },
        async assignListEntreprise({commit}, payload){
            commit('SET_LIST_ENTREPRISE',payload)
        },
        switchDialogEntreprise:({commit}, payload) => commit('SET_DIALOG_Entreprise', payload)
    } 
}