<template>
  <v-container fluid>
    <div class="" style="padding: 0 50px;">
      <v-toolbar flat v-appcolor>
        <v-toolbar-title class="white--text" style="font-size:medium">LISTE DES CLIENTS</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
          rounded class="d-none d-sm-flex white black--text" style="width:30%; max-width: 30%;">
        </v-text-field>
      </v-toolbar>
      <v-data-table :headers="headers" :items="listeEntreprise" :search="search" sort-by="calories" class="elevation-1">
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
              dense rounded class="d-flex d-sm-none white black--text" size="15px">
            </v-text-field>
            <v-dialog persistent v-model="dialogDelete" max-width="700px">
              <v-card>
                <v-card-title class="text-h5" v-appcolor>Are you sure you want to delete this item?</v-card-title>
                <v-card-text>
                  <table>
                    <tbody>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Email:</th>
                          <td><span>{{ entreprise.email }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Contact:</th>
                          <td><span>{{ entreprise.contact }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Code:</th>
                          <td><span>{{ entreprise.code }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Type Suscription:</th>
                          <td><span>{{ entreprise.type }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Date Suscription:</th>
                          <td><span>{{ entreprise.created_at }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Dure:</th>
                          <td><span>{{ entreprise.duree }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Temp ecoulé:</th>
                          <td><span>{{ entreprise.reminingDay }}</span></td>
                        </v-list-item-title>
                      </tr>
                      <tr>
                        <v-list-item-title class="mb-1" align="left">
                          <th>Date Fin:</th>
                          <td style="text-align: right;"><span>{{ entreprise.dateFin }}</span></td>
                        </v-list-item-title>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon medium class="mr-2" @click="navigateTo(item)" color="info" title="Editer">
            fa-duotone fa-pen-to-square
          </v-icon>
          <v-icon medium class="mr-2" @click="viewDetails(item)" color="success" title="Details">
            fas fa-eye
          </v-icon>
          <v-icon medium class="mr-2" @click="activateDeactivate(item)" :color="item.active ? 'info' : 'warning'"
            title="Activer/Déactivé">
            {{ item.active ? `fas fa-lock-open` : `fas fa-lock` }}
          </v-icon>
          <v-icon medium @click="deleteItem(item)" color="red" title="Supprimer">
            fa-duotone fa-trash-can
          </v-icon>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<style lang="scss" scoped>
table {
  tbody {
    tr {
      border: 2px solid white;
      margin-bottom: 3rem;

      span :first-child {
        text-align: left;
        font-weight: 600;
        font-size: 16px;
      }

      td {
        font-size: 16px;
        text-align: end;
      }
    }
  }

}
</style>
<script>
import SuscriptionManager from '@/services/Suscription.js'
import moment from 'moment'
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'NOM ENTREPRISE',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      { text: 'EMAIL', value: 'email' },
      { text: 'CONTACT', value: 'contact' },
      { text: 'TYPE SUSCRIPTION', value: 'type' },
      { text: 'DUREE', value: 'duree' },
      { text: 'TEMPS ECOULER', value: 'reminingDay' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    listeEntreprise: [],
    editedIndex: -1,
    entreprise: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouveau Client' : 'Modification'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise', 'getAppReloaded']),


    initialize() {

      SuscriptionManager.getAll().then((rep) => {
        rep.data.forEach(item => {
          let data = {
            id: item.entreprise.id,
            name: item.entreprise.name,
            email: item.entreprise.email_address,
            contact: item.entreprise.telephone,
            code: item.entreprise.code,
            type: item.type_suscription,
            active: item.entreprise.active,
            created_at: moment(item.created_at).format('DD-MM-YYYY'),
            reminingDay: moment(item.created_at).endOf('day').fromNow(),
            duree: item.duree,
            dateFin: this.calculDateFin(item.created_at, item.duree)
          }
          this.listeEntreprise.push(data)
        });
      }).catch((error) => {
        console.log(error)
      })
    },

    activateDeactivate(item) {
      SuscriptionManager.deactivateActivateEntreprise(item.id)
        .then((rep) => {
          this.listeEntreprise.forEach((item) => {
            if (item.id == rep.data.id) {
              item.active = rep.data.active
            }
          })
        })
        .catch(error => console.log("error :", error))
    },

    viewDetails(item) {
      console.log(item);
      this.dialogDelete = true;
      this.entreprise = item
    },

    navigateTo() {
      this.$router.replace('type-suscription')
    },

    calculDateFin(data, duree) {
      if (parseInt(duree) == 10) {
        return moment(data).add(duree, 'days').format('DD-MM-YYYY')
      } else {
        return moment(data).add(duree, 'month').format('DD-MM-YYYY')
      }

    },

    editItem(item) {
      this.editedIndex = this.listeEntreprise.indexOf(item)
      this.clientForm = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.listeEntreprise.indexOf(item)
      this.clientForm = Object.assign({}, item)
      this.dialogDelete = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.clientForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.clientForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },


    errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    infoMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
  },
}
</script>