<template>
    <v-app style="background:#DDDDDD">
        <v-container>
            <v-alert v-if="errors.message" dense :type="errors.type" style="height: 60px; padding-to: 10px">
                {{ errors.message }}
            </v-alert>
            <v-row>
                <v-col cols="12" md="6" sm="12" class="mx-auto">
                    <template>
                        <v-card outlined>
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-1">
                                        <u><span id="textInfo" v-size="16">{{ textInfo }} </span></u>
                                    </div>
                                    <div class="max-auto">
                                        <v-avatar fab color="cyan" elevation="0"  size="100px" @click="switchForm()" style="cursor: pointer;">
                                            <v-img src="../../assets/avatar.png"></v-img>
                                        </v-avatar>
                                    </div>
                                    <div>
                                        <InfoUser v-if="!edition" />
                                        <EditUser v-on:updatedSucces="switchForm()" v-if="edition" />
                                    </div>
                                </v-list-item-content>
                            </v-list-item>

                            <v-card-actions>
                                <v-btn text :color="edition ? 'primary' : 'success'" outlined rounded @click="switchForm()"
                                    class="mr-2">
                                    <v-icon size="25px" class="mr-1">{{ switchBtn.icon }}</v-icon>
                                    <span>{{ switchBtn.text }}</span>
                                </v-btn>
                                <v-btn text color="primary" @click="navigateTo('/')">
                                    <v-icon size="25px" class="mr-1">fa-brands fa-accusoft</v-icon>
                                    <span>Accueil</span>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
import InfoUser from './InfoUser.vue'
import EditUser from './EditUser.vue'
import { mapGetters } from 'vuex'
import Message from '@/services/Message'
export default {
    components: { InfoUser, EditUser },
    data() {
        return {
            tab: null,
            edition: false,
            errors: this.getErrors(),
        }
    },
    // Problème: au niveau des habilitations quand j'attribut une habilitation 
    // à un user la liste des habilitations ne s'actualise pas pour un autre user
    // sauf si je quitte sur la page et revien en plus cela cause une erreur
    // dans le store dont il faut chercher la cause.
    computed: {
        textInfo() {
            return this.edition ? 'Edition de compte' : 'Profile'
        },
        switchBtn() {
            return this.edition ? { 'text': 'Details', 'icon': 'mdi-account-details' } :
                { 'text': 'Editer', 'icon': 'mdi-account-edit' }
        },
    },
    created() {
        this.$store.dispatch("makeErros", Message.createMessage(''))
    },
    methods: {
        ...mapGetters(['getErrors']),
        switchForm() {
            this.edition = !this.edition;
            console.log(this.edition)
        },
        navigateTo(path) { this.$router.push(path) }
    },
}
</script>
<style scoped>
#textInfo {
    font-style: italic;
    font-weight: 600;
    color: '#114E60';
}
</style>