<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-spacer></v-spacer>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Recette Vente et Maitenance' : 'Sales and Maintenance Recipe' }}</v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Recette Vente et Maitenance' : 'Sales and Maintenance Recipe' }}</v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <template v-slot:cbody>
                <v-card-title class="mt-6" style="color: #114E60;">
                    <v-spacer></v-spacer>
                    <span class="ml-8" v-size="20" style="font-weight: bold;">
                        {{ (totalVete + totalMaint) |localFormat}}{{ getEntreprise().type_monaie }}
                    </span>
                    <v-spacer></v-spacer>
                </v-card-title>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import { mapGetters } from 'vuex';
export default {
    components: { FormTemplate},
    data() {
        return {
            totalVete:0,
            totalMaint:0,
        }
    },

    props:{
        getTotalVente:null,
        getTotalMaint:null,
    },
    watch:{
        getTotalVente(val){
            this.totalVete = val
        },
        getTotalMaint(val){
            this.totalMaint = val
        },
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getEntreprise'])
    }

}
</script>
<style lang="">
    
</style>