<template>
    <v-container fluid id="card">
        <v-row class="">
            <v-col cols="12" xs="12" sm="6" md="4" lg="4" class="mb-5 d-sm-block" v-for="(item, i) in $i18n.locale == 'fr' ? frItems: enItems" :key="i">
                <v-card v-appcolor elevation="5" class="mx-auto rounded-md" max-width="430">
                    <v-row class="d-xs-block">
                        <v-col cols="6" xs="12" sm="6" md="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-card-subtitle v-text="item.text" style="color:whitesmoke;  font-size: 15px; ">
                                    </v-card-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="6" xs="12" sm="6" md="6" class="ml-n5">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-img height="80px" contain style=" color:whitesmoke"
                                        :src="`${baseUrl}/icons/${item.code}`"></v-img>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col v-if="i == 0" cols="12" sm="8" style="color:bisque;  font-size: 2rem;">
                            <span>{{ nb_piece }}</span>
                        </v-col>
                        <v-col v-if="i == 1" cols="12" sm="8" style="color:bisque;  font-size: 2rem;">
                            <span>{{ nb_accessoire }}</span>
                        </v-col>
                        <v-col v-if="i == 2" cols="12" sm="8" style="color:bisque;  font-size: 2rem;">
                            <span>{{ nb_appareil }}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import AppareilManager from '@/services/AppareilManager';
import ArticleManager from '@/services/ArticleManager';
import { mapGetters } from 'vuex'
export default {
    name: "DashboardVue",
    data() {
        return {
            nb_piece: 0,
            nb_accessoire: 0,
            nb_appareil: 0,
            icon_like: '',
            baseUrl: '',
            token: localStorage.getItem('accessToken'),
            frItems: [
                { text: "TOTOAL PIECES", code: "total_piece.png" },
                { text: "TOTOAL ACCESSOIRE", code: "total_accessory.png" },
                { text: "NON REPARÉ", code: "appareil_unrepaire.png" },
            ],
            enItems: [
                { text: "TOTAL PARTS", code: "total_piece.png" },
                { text: "TOTAL ACCESSORY", code: "total_accessory.png" },
                { text: "NOT REPAIRED", code: "appareil_unrepaire.png" },
            ],
            donne: [this.nb_piece, this.nb_accessoire, this.nb_appareil]

        }
    },
    created() {
        this.loadArticle()
        this.countUnFixedAppareil()
        this.getUrl()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'isAuthenticated', 'getEntreprise']),
        ...mapGetters('appareils', ['getListAppareil', 'isAuthenticated']),
        loadArticle() {
            //Definir cette method dans ArticleManager ???????
            ArticleManager.allArticle(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    rep.data.forEach(el => {
                        if (el.categorie == 1)
                            this.nb_piece += 1
                        else
                            this.nb_accessoire += 1
                    });
                })
                .catch((err) => {
                    console.log(err.data)
                })
        },
        countUnFixedAppareil() {
            AppareilManager.listUnfixedAppareil(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    this.nb_appareil = rep.data.length
                })
                .catch((err) => {
                    console.log(err.data)
                })
        },
        getUrl() {
            this.baseUrl = window.document.location.href
            console.log(this.baseUrl)
        },
    },

}
</script>

<style>
#card {
    margin-top: -3rem;
}
</style>