<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <FormTemplate>

                    <template v-slot:header>
                        <v-toolbar-title>{{ $t('service.client.formTitle') }}</v-toolbar-title>
                    </template>
                    <template v-slot:cbody>
                        <AppareilForm :formElement="editApparilForm" :deletedAppareil="isAppareilDeleted"
                            v-on:TicketLoaded="isAppareilDeleted = false" />
                    </template>
                </FormTemplate>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-toolbar flat v-appcolor>
                    <v-toolbar-title class="white--text" style="font-size:medium">
                        {{ $t('service.client.notPreparedAppareilListTitle').toLocaleUpperCase() }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                        dense rounded class="d-none d-sm-flex white black--text" size="15px"
                        style="width:50%; max-width: 50%;">
                    </v-text-field>
                </v-toolbar>
                <v-data-table :headers="headers" :items="listeAppareil" :search="search" sort-by="calories"
                    class="elevation-1">
                    <template v-slot:top>
                        <v-toolbar flat>

                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                                filled dense rounded class="d-flex d-sm-none white black--text" size="15px">
                            </v-text-field>

                            <v-dialog persistent v-model="dialogDelete" max-width="500px">
                                <v-card>
                                    <v-card-title class="text-h5">Are you sure you want to delete this item?
                                    </v-card-title>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                        <v-spacer></v-spacer>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                            fa-duotone fa-pen-to-square
                        </v-icon>
                        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
                            fa-duotone fa-trash-can
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ClientManager from '@/classes/clients/ClientManager'
import AppareilForm from './AppareilForm.vue'
import AppareilManager from '@/services/AppareilManager';
import FormTemplate from '../FormTemplate.vue'
import { mapGetters } from "vuex";

export default {
    components: {
        AppareilForm,
        FormTemplate
    },
    data() {
        return {
            dialog: false,
            dialogDelete: false,
            search: '',
            desable: false,
            headers: this.$i18n.locale =='fr' ?[
                { text: 'Appareil', align: 'start', sortable: true, value: 'libelle', },
                { text: 'Panne', value: 'panne' },
                { text: 'Ticket', value: 'ticket.number' },
                { text: 'Client', value: 'client.tel' },
                { text: 'Prix a payer', value: 'total_apayer' },
                { text: 'Montant reçu', value: 'total_payer' },
                { text: 'Reste a payer', value: 'rest_apayer' },
                { text: 'Actions', value: 'actions', sortable: false },
            ]:[
                { text: 'Divice', align: 'start', sortable: true, value: 'libelle', },
                { text: 'Problem', value: 'panne' },
                { text: 'Ticket', value: 'ticket.number' },
                { text: 'Customer', value: 'client.tel' },
                { text: 'Price to pay', value: 'total_apayer' },
                { text: 'Amount received', value: 'total_payer' },
                { text: 'Remains to pay', value: 'rest_apayer' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            // headers: this.$i18n.locale =='en' [
            //     { text: 'Divice', align: 'start', sortable: true, value: 'libelle', },
            //     { text: 'Problem', value: 'panne' },
            //     { text: 'Ticket', value: 'ticket.number' },
            //     { text: 'Customer', value: 'client.tel' },
            //     { text: 'Price to pay', value: 'total_apayer' },
            //     { text: 'Amount received', value: 'total_payer' },
            //     { text: 'Remains to pay', value: 'rest_apayer' },
            //     { text: 'Actions', value: 'actions', sortable: false },
            // ],
            listeAppareil: [],
            editedIndex: -1,
            editApparilForm: null,
            isAppareilDeleted: false,
            dataInitialize: false,
            appareilForm: {
                id: '',
                libelle: null,
                panne: null,
                client: null,
                ticket: null,
            },
            defaultItem: {
                id: '',
                libelle: null,
                panne: null,
                client: null,
                ticket: null,
            },
        }
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nouvel enregistremetn' : 'Modification'
        },
        refreshed() {
            return this.getAppReloaded()
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        refreshed() {
            this.initialize()
        }

    },

    created() {
        this.initialize()
    },

    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise', 'getAppReloaded']),
        ...mapGetters('appareils', ['']),
        initialize() {
            AppareilManager.listUnfixedAppareil(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    this.listeAppareil = rep.data
                }).catch((error) => {
                    console.log(error)
                })
        },
        save() {
            let cm = new ClientManager()
            console.log('optin: ', this.editedIndex);
            if (this.editedIndex > -1) {
                cm.update('clients/register/', this.appareilForm,
                    this.appareilForm.id, this.getAccessToken())
                    .then((rep) => {
                        console.log(rep)
                        this.initialize()
                    }).catch((error) => { console.log(error.response.data) })
            }
            else {

                cm.add('clients/register/', this.appareilForm, this.getAccessToken())
                    .then((rep) => {
                        console.log(rep)
                        this.initialize()
                    }).catch((error) => { console.log(error.response.data) })
            }
            this.close()
        },
        deleteItemConfirm() {
            AppareilManager.deleteAppariel(this.appareilForm.id, this.getAccessToken())
                .then(() => {
                    this.initialize()
                    this.isAppareilDeleted = true
                }).catch((error) => { console.log(error.response.data) })
            this.closeDelete()
            return true
        },
        editItem(item) {
            this.editedIndex = this.listeAppareil.indexOf(item)
            this.editApparilForm = Object.assign({}, item)
            console.log(this.appareilForm.id)
        },

        deleteItem(item) {
            this.editedIndex = this.listeAppareil.indexOf(item)
            this.appareilForm = Object.assign({}, item)
            this.dialogDelete = true
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.appareilForm = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedIndex = -1
            })
        },


    },
}
</script>