import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";
export default class MaintenaceManager{
    static newMaintenance(data=null,token= null){
        return new Promise(function(resolve, reject){
            let response = new ApiServiceD()
            .add('maintenances/register/', data, null, token)
            .then(()=>resolve(response))
            .catch((error)=>reject(error))
        })
    }
    static listMaintenance(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('maintenances/register/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                store.dispatch('maintenance/fetchlistMaintenance',rep.data);
                resolve(rep.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    static statistiqueMaintenance(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('statistique/maintenance/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static statistiqueMaintenanceMois(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('statistique/maint/mois/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}