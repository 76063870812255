<template id="loginviw">
    <div id="contai">
        <v-container class="loginView">
            <div class="text-center mx-auto" id="logo">
                <v-img id="img" src="../assets/logo-avec-fond1.png" class="mx-auto" contain>
                </v-img>
            </div>
            <v-container class="fill-height" fluid>
                <v-row>
                    <v-col style="margin-top: 5rem;" cols="12" md="6" sm="12">
                        <v-card class="elevation-5">
                            <LoginForm />
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                        <v-card id="text-politique">
                            <v-card-text>
                                <p class="text-h5  text--accent-3" style="color:#114E60;">
                                    {{$t("loginView.politic")}}
                                </p>
                                <div class="text--primary" id="politique-commercial">
                                    
                                    <i18n path="loginView.desc1" tag="p">
                                        <span style='font-weight: 900; font-size: large;' v-t="'appName'" />
                                    </i18n>
                                    <p class="text-h5 text--primary">{{$t("loginView.mode1")}}</p>
                                    <p>
                                        {{$t("loginView.desc2")}} <br>
                                    </p>
                                    <p class="text-h5 text--primary">{{$t("loginView.mode2")}}</p>
                                    <p>
                                        {{$t("loginView.desc3")}} <br>

                                    </p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>
<script>
import LoginForm from "../components/Account/LoginForm.vue";
export default {
    components: { LoginForm },
    data: () => ({
        stop: 1,
        name: "LoginVue",
    }),
}
</script>
<style lang="scss">
#contai {
    height: 100%;
    background-size: cover;
    /* Background image is fixed in the viewport so that it doesn't move when
            the content's height is greater than the image's height */
    background-attachment: fixed;
    // background-position: center center;
}

#politique-commercial {
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#text-politique {
    text-align: left;
    top: 8rem;
}

@media only screen and (max-width: 960px) {
    #text-politique {
        top: 1rem;
    }
}
</style>