<template>
    <v-container class="mt-5" style="background-color:whitesmoke">
        <v-toolbar color="white--text" flat class="mb-5" v-appcolor>
            <v-toolbar-title class="d-none d-sm-flex">
                {{ lang == 'fr' ? 'NOUVEAU' : 'ADD NEW' }}
            </v-toolbar-title>
            <v-toolbar-title class="d-flex d-sm-none" style="font-size:small">
                {{ lang == 'fr' ? 'NOUVEAU' : 'ADD NEW' }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog persistent v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" :sm="false" v-bind="attrs" v-on="on">
                        {{ $t('buttons.addNew') }}
                    </v-btn>
                    <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                        v-bind="attrs" v-on="on">
                        <v-icon>fa-regular fa-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title v-appcolor>
                        <span class="text-h5">{{ lang == 'fr' ? frFormTitle : enFormTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-combobox v-model="selectArticle" :items="listArticle" :rules="requireRule"
                                            :label="lang == 'fr' ? 'Article' : 'Article'" item-value="id"
                                            item-text="libelle" dense class="mt-8" chips clearable
                                            @change="setSelectArticle">
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field :rules="requireRule" v-model="formLigneAppro.quantite"
                                            :label="lang == 'fr' ? 'Quantité' : 'Quantity'" required class="mt-7">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field v-model="formLigneAppro.pau"
                                            :label="lang == 'fr' ? 'Prix achat' : 'Purchase price'" required class="mt-7">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="close">
                            Cancel
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-combobox v-model="selectedFournisseur" :items="listFournisseur" :rules="requireRule"
                    :label="lang == 'fr' ? 'Fournisseur' : 'Supplyer'" item-value="id" item-text="full_name" dense
                    class="mt-1" @change="setSelectFournisseur" chips clearable>
                </v-combobox>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" id="my-table" :items="lingeAppro" sort-by="calories" class="elevation-1"
                    hide-default-footer>
                    <template v-slot:top>

                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                            fa-duotone fa-pen-to-square
                        </v-icon>
                        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
                            fa-duotone fa-trash-can
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4" align="left">
                <v-btn class="mr-4" text color="primary" type="submit" @click="submit">
                    {{ $t('buttons.save') }}
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" align="right" class="mt-1">
                {{ lang == 'fr' ? 'Coût total' : 'Total cost' }} &nbsp; &nbsp;
                {{ totalPrice | localFormat }}{{ getEntreprise().type_monaie }}
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import FournisseurManager from '@/services/FournisseurManager';
import ApprovisonnementManager from '@/services/ApprovisonnementManager';
import ArticleManager from '@/services/ArticleManager';
import CaisseManger from '@/services/Caisse.js'
import { mapGetters } from 'vuex'
export default {

    data() {
        return {
            dialog: false,
            valid: true,//Form validation
            dialogDelete: false,
            listArticle: [],
            listFournisseur: [],
            fournisseur_id: 0,
            selectedFournisseur: null,
            is_duplicate: false,//check in other to avoid duplication listArticle
            selectArticle: null,//Selected article
            totalPrice: 0,//Total price of approvisonnement
            soldeCaisse: 0,
            soldeBenefice: 0,

            headers: this.$i18n.locale == 'fr' ? [
                { text: "Article", value: "libelle" },
                { text: "Quanite", value: "quantite" },
                { text: "Prix Achat", value: "pau" },
                { text: "Actions", value: "actions", sortable: false },
            ] : [
                { text: "Article", value: "libelle" },
                { text: "Quanity", value: "quantite" },
                { text: "Purchase price", value: "pau" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            // Validation rules
            requireRule: [
                v => !!v || 'Ce champ est obligatoire',
            ],

            formLigneAppro: {
                id: "",
                libelle: "",
                pau: "",
                quantite: "",
            },
            defaultItem: {
                libelle: "",
                pau: "",
                quantite: "",
            },

            lingeAppro: [],
            editedIndex: -1,

        }
    },
    computed: {
        frFormTitle() {
            return this.editedIndex === -1 ? "Ajouter un article" : "Modification";
        },
        enFormTitle() {
            return this.editedIndex === -1 ? "Add an article" : "Modification";
        },
        getDocumentSize() {
            return this.documents ? this.documents.length : 0;
        },
        lang() {
            return this.$store.state.locale
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    created() {
        this.loadArticle()
        this.loadFournisseur()
        this.loadCaisseSolde()
        this.loadBeneficeSolde()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        save() {
            if (this.$refs.form.validate() == true) {

                if (this.editedIndex > -1) {
                    Object.assign(this.lingeAppro[this.editedIndex], this.formLigneAppro);
                    // Calcule du coût total de l'approvissonnement
                    this.calculeCoutTotal()
                    this.close();
                }
                else {
                    let rep = this.checkDuplicate(this.formLigneAppro.id)
                    if (parseInt(rep) == -1) {
                        let data = {
                            id: this.formLigneAppro.id,
                            libelle: this.formLigneAppro.libelle,
                            pau: this.formLigneAppro.pau,
                            quantite: this.formLigneAppro.quantite,
                        }
                        // Calcule du coût total de l'approvissonnement
                        this.totalPrice += (parseInt(this.formLigneAppro.pau) * parseInt(this.formLigneAppro.quantite))
                        console.log(data)
                        this.lingeAppro.push(data)
                        // console.log(this.lingeAppro)
                        this.close();
                    } else {
                        this.errorMessage("Cet article existe déjà sur la list.")
                    }

                }
                this.is_duplicate = false
            }
        },
        submit() {
            if (this.lingeAppro.length > 0) {
                if (this.fournisseur_id) {
                    if (confirm("Voudriez-vous faire un décaissement ?")) {
                        if (parseInt(this.totalPrice) <= parseInt(this.soldeCaisse)) {
                            console.log("Le prix total : ", this.totalPrice)
                            let appro = {
                                "data": this.lingeAppro,
                                "fournisseur": this.fournisseur_id,
                                "totalprice": this.totalPrice,
                                "entreprise": this.getEntreprise().id
                            }
                            ApprovisonnementManager.approvisionner(appro, this.getAccessToken())
                                .then(() => {
                                    this.$emit('submit')
                                    this.successMessage("Enregistrement effectué avec succès.")
                                    this.claen()

                                }).catch((error) => {
                                    console.log(error)
                                });
                        } else {
                            this.errorMessage("Le solde de caisse inssuffisant")
                        }
                    }
                    else {
                        let appro = {
                            "data": this.lingeAppro,
                            "fournisseur": this.fournisseur_id,
                            "entreprise": this.getEntreprise().id
                        }
                        ApprovisonnementManager.approvisionner(appro, this.getAccessToken())
                            .then(() => {
                                this.$emit('submit')
                                this.successMessage("Enregistrement effectué avec succès.")
                                this.claen()

                            }).catch((error) => {
                                console.log(error)
                            });
                    }
                    this.totalPrice = 0
                } else {
                    this.errorMessage("Vous devez choisir un fournisseur")
                }

            } else {
                this.errorMessage("Veillez ajouter des articles a revendre")
            }
        },
        calculeCoutTotal() {
            this.totalPrice = 0
            this.lingeAppro.forEach(el => {
                this.totalPrice += (parseInt(el.pau) * parseInt(el.quantite))
            });
        },
        loadCaisseSolde() {
            CaisseManger.soldeCaisse(this.getEntreprise().id, this.getAccessToken())
                .then(rep => {
                    this.soldeCaisse = parseInt(rep.data.solde)
                    console.log("Solde de la caisse: ", this.soldeCaisse)
                })
                .catch(err => {
                    console.log(err.data)
                })
        },
        loadBeneficeSolde() {
            CaisseManger.soldeBenefice(this.getEntreprise().id, this.getAccessToken())
                .then(rep => {
                    this.soldeBenefice = rep.data.solde
                    console.log("Solde de la benefice: ", this.soldeBenefice)
                })
                .catch(err => {
                    console.log(err.data)
                })
        },
        claen() {
            this.lingeAppro = []
            this.selectedFournisseur = ''
        },
        setSelectArticle() {
            if (this.selectArticle) {
                this.formLigneAppro.id = this.selectArticle.id
                this.formLigneAppro.libelle = this.selectArticle.libelle
            }
        },
        setSelectFournisseur() {
            if (this.selectedFournisseur) {
                this.fournisseur_id = this.selectedFournisseur.id
            }
        },
        loadArticle() {
            ArticleManager.listPiece(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    console.log(rep.data[0])
                    this.listArticle = rep.data[0]
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        loadFournisseur() {
            FournisseurManager.listFournisseur(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    this.listFournisseur = rep.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        /**
         * Avoid duplicate item in the lingeAppro
         */
        checkDuplicate(val) {
            let result = this.lingeAppro.findIndex(el => el.id == val)
            return result
        },

        editItem(item) {
            this.editedIndex = this.lingeAppro.indexOf(item);
            this.formLigneAppro = Object.assign({}, item);
            this.selectArticle = item.libelle
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.lingeAppro.indexOf(item);
            this.formLigneAppro = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.lingeAppro.splice(this.editedIndex, 1);
            this.calculeCoutTotal()
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.selectArticle = null
            this.$refs.form.reset()
            this.is_duplicate = false
            this.selectedFournisseur = null,
                this.$nextTick(() => {
                    this.formLigneAppro = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.formLigneAppro = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        infoMessage(message) {
            this.$toast.info(message, {
                position: "bottom-right",
                timeout: 4095,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        errorMessage(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                timeout: 4095,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        successMessage(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                timeout: 4095,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },

    },

}
</script>
<style>
#dPiker {
    margin-left: -1rem;
}
</style>