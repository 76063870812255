<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-spacer></v-spacer>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Dépense mensuelle' : 'Monthly expenditure' }}
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Dépense mensuelle' : 'Monthly expenditure' }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <template v-slot:cbody>
                <v-card-title class="mt-2" style="color: #114E60;">
                    <v-spacer></v-spacer>
                    <span class="ml-8" v-size="20" style="font-weight: bold;">
                        {{soldeDepense |localFormat}}{{ getEntreprise().type_monaie }}
                    </span>
                    <v-spacer></v-spacer> 
                </v-card-title>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
// import ApiServiceD from '@/classes/ApiServiceD';
import {mapGetters} from 'vuex'
export default {
    components: { FormTemplate},
    data() {
        return {
            soldeDepense:0,
        }
    },
    created() {
        this.initialize()
    },
    watch:{
        controleDepense(){
            this.initialize()
        }
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
        controleDepense(){
            return this.getDepenseUpdated()
        }
    },
    methods: {
        ...mapGetters(['getAccessToken','getEntreprise']),
        ...mapGetters("comptable",['getDepenseUpdated','getSoldeDepense']), 
        initialize(){
            this.soldeDepense = this.getSoldeDepense()
        },
    },
}
</script>
<style lang="">
    
</style>