<template lang="">
    <v-container>
        <v-divider></v-divider>
        <v-card elevation-1>
            <v-card-text>
                <!-- Enregister un ticket -->
                <v-row>
                    <v-col cols="12" md="6" sm="6">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                v-model="Form.number"
                                :label=" lang =='fr' ? 'Numéro de ticket':'Ticket number'"
                                :name=" lang =='fr' ? 'Numéro de ticket':'Ticket number'"
                                prepend-icon="fa-duotone fa-ticket"
                                type="number"
                                color="cyan darken-3"
                                :rules="requireRules" required />
                               
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                        <v-btn class="mt-3" align="left" v-appcolor @click="save">
                            {{$t('buttons.addNew')}}
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- Liste des tickets disponibles -->
                    <v-card-title><u>
                        {{ lang =='fr' ? 'Tickets disponible':'Tickets available'}}
                    </u></v-card-title>
                    <v-divider class="mb-5 mt-n3"></v-divider>
                <v-row class="d-xs-block">
                    
                    <div v-for="(item, i) in listTicket" :key="i">
                        <v-col cols="12" md="2" sm="2">
                            <v-sheet class="pt-5"
                                color="white"
                                elevation="4"
                                height="58"
                                width="81"
                            >
                                <span  v-size="20">{{item.number}}</span>
                            </v-sheet>
                        </v-col>
                    </div>
                </v-row>
                <!-- Les ticket non disponible -->
                <v-divider class="mb-2 mt-n3"></v-divider>
                <v-card-title><u>
                    {{ lang =='fr' ? 'Tickets non disponible':'Tickets unavailable'}}
                </u></v-card-title>
                <v-row>
                    <v-col cols="12" md="6" sm="6">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                v-model="Form.number"
                                :label=" lang =='fr' ? 'Numéro de ticket':'Ticket number'"
                                :name=" lang =='fr' ? 'Numéro de ticket':'Ticket number'"
                                prepend-icon="fa-duotone fa-ticket"
                                type="number"
                                color="cyan darken-3"
                                :rules="requireRules" required />
                        </v-form>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                        <v-btn rounded v-appcolor dark @click="activate(false)" class="mr-2 mb-2">
                          {{$t('buttons.deactivate')}}
                        </v-btn>
                        <v-btn rounded v-appcolor dark @click="activate(true)" class="mr-2 mb-2">
                          {{$t('buttons.activate')}}
                        </v-btn>
                    </v-col>
                </v-row>
                    
                <v-row class="d-xs-block" v-if="is_ticket_unavailable">
                    
                    <div v-for="(item, i) in listTicketNonDisponible" :key="i">
                        <v-col cols="12" md="2" sm="2">
                            <v-sheet class="pt-5"
                                color="white"
                                elevation="4"
                                height="58"
                                width="81"
                            >
                                <span  v-size="20">{{item.number}}</span>
                            </v-sheet>
                        </v-col>
                    </div>
                </v-row>
            </v-card-text>
        </v-card>
        <AlertViewVue :statusCode="alertData"/>
    </v-container>
</template>
<script>
import ApiServiceD from '@/classes/ApiServiceD';
import TicketManager from '@/services/TicketManager';
import AlertViewVue from '../AlertView.vue';
import { mapGetters } from 'vuex';
export default {
    components: { AlertViewVue },
    name: 'ListeTicket',
    data() {
        return {
            valid: true,
            listTicket: [],
            listTicketNonDisponible: [],
            is_ticket_unavailable: false,
            checkbox: [],
            alertData: [],
            token: localStorage.getItem('accessToken'),
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
            Form: {
                number: '',
                entreprise: '',
            }
        }
    },
    created() {
        this.initialize()
        this.brokendticket()
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        // Formulaire pour completer les information de user et celle de l'entreprise ????
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        initialize() {
            TicketManager.listTicketAvailable(this.getEntreprise().id, this.getAccessToken())
                .then(rep => {
                    this.listTicket = rep.data
                    console.log("List des tickets: ",rep.data);
                })
                .catch(err => {
                    console.log("Error ticket 1: ",err.response.data);
                })
        },
        brokendticket() {
            TicketManager.listTicketNoneAvailable(this.getEntreprise().id, this.getAccessToken())
                .then(rep => {
                    if (rep.data.length > 0) {
                        this.is_ticket_unavailable = true
                    }
                    this.listTicketNonDisponible = rep.data
                })
                .catch((err) => {
                    console.log("Error ticket 2: ",err.response);
                })
        },
        save() {
            if (this.$refs.form.validate()) {
                let rep = this.checkDuplicate(this.Form.number)
                if (parseInt(rep) == -1) {
                    this.Form.entreprise = this.getEntreprise().id
                    new ApiServiceD().add('tickets/register/', this.Form,null ,this.getAccessToken())
                        .then(() => {
                            this.$refs.form.reset()
                            this.initialize()
                            this.brokendticket()
                            this.alertData = ['success', 'Enregistrement effectué avec succès']
                        })
                        .catch(err => {
                            console.log(err.data);
                        })
                } else
                    this.alertData = ['error', 'Ce ticket exist déjà']
            }
        },
        activate(val) {
            let donne = { 'number': this.Form.number, 'active': val, 'entreprise':this.getEntreprise().id }
            TicketManager.registerBrokenTicket(donne, this.getAccessToken())
                .then(() => {
                    this.initialize()
                    this.brokendticket()
                })
                .catch(err => {
                    console.log(err.data);
                })
        },
        checkDuplicate(val) {
            return this.listTicket.findIndex(el => el.number == val)
        },
    },

}
</script>
<style lang="scss">
#sheet {
    display: inline-block;
}
</style>