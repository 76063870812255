import Vue from "vue";
import Vuex from "vuex";
import dettes from "./modules/dettes";
import comptable from "./modules/comptable";
import appareils from "./modules/appareils";
import maintenance from "./modules/maintenance";
import compentrole from "./modules/CompoentRole";
import entreprise from "./modules/entreprise";
import serviceaccess from "./modules/serviceAcces";
import categorie from "./modules/categorie";
import ventes from "./modules/ventes";
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  
  state: {
    accessToken: null,
    authenticated: false,
    user: null,
    showLoader: false,
    errros: { message: "", type: "" },
    seletedUser: null,//For adding access rights
    userComponents: "",
    listRole: [],
    listUsers: [],
    accessMenu: [],
    showDialogRoles: false,
    appReload: false,
    menuIsActive: true,
    locale: i18n.locale,
  },
  getters: {
    getAccessToken (state){
      if(state.accessToken == null){
        return state.accessToken = localStorage.getItem('token')
      }
      return state.accessToken
    },

    getMenuItemIsActive: (state) => !state.menuIsActive,

    getLoader: (state) => state.showLoader,

    getAppReloaded: (state) => state.appReload,

    getUser: (state) => state.user,

    getErrors: (state) => state.errros,

    getEntreprise: (state) => state.user.entreprise,

    getShowDialogRoles: (state) => state.showDialogRoles,

    isAuthenticated: (state) => (state.accessToken != null ? true : false),

    isMaster:(state) => state.user.is_super,
     
    getUserComponents:(state) => state.userComponents,
    
    getAccessMenu:(state) => state.accessMenu,

    getSeletedUser:(state) => state.seletedUser
  },

  mutations: {
    setAccessToken(state, accessToken) {
      state.authenticated = true;
      state.accessToken = accessToken;
      var token_name = accessToken.split('.').pop();
      localStorage.setItem(token_name, accessToken);
    },

    setUser(state, user) {
      state.user = user;
      state.userComponents = user.components;
    },

    assignErrors(state, message){
      state.errros.message = message.text, state.errros.type = message.type
      setTimeout(function(){
        state.errros.message = ""
      },3000);
    },

    assignListUser: (state, user) => (state.listUsers = user),

    setShowDialogRole(state, value) {
      state.seletedUser = value;
      state.showDialogRoles = true;
    },

    closeDialog(state){state.showDialogRoles=false},
    
    UPDATE_USER(state, data){
      state.user.username = data.username;
      state.user.first_name = data.first_name;
      state.user.last_name = data.last_name;
      state.user.tel = data.telephone;
      state.user.email = data.email;
    }, 

    /** 
     * On construit le menu dont le utilisateur connecté doit avoir accès
     */
    SET_ACCESS_MENU(state, data){
      
      if(data.user.is_super){
          state.accessMenu = data.menuItems
      }else{
        data.menuItems.map((elem) =>{
              console.log(elem.text)
              if(state.userComponents.some(comp => (comp.libelle).toLowerCase() == (elem.text).toLowerCase())){
                  state.accessMenu.push(elem)
              }
          })
      }
    },

    LOGOUT_USER(state){
      var token_name = state.accessToken.split('.').pop();
      localStorage.removeItem(token_name);
      state.accessToken = null;
      state.authenticated = false;
      state.user = null;
    },

    REFRESH_APP:(state)=> state.appReload = ! state.appReload,

    updateLocale(state, newLocale) {
      state.locale = newLocale
    }

  },
  actions: {
    changeLocale({ commit }, newValue) {
      i18n.locale = newValue
      commit('updateLocale', newValue)
    },
    
    setAccessToken: ({ commit }, accessToken) =>
      commit("setAccessToken", accessToken),

    setUser: ({ commit }, user) => commit("setUser", user),

    makeErros: ({ commit }, message) => commit("assignErrors", message),

    setListUser: ({ commit }, payload) => commit("assignListUser", payload),

    logoutUser:({commit}) => commit('LOGOUT_USER'),
    
    reloadApp:({commit}) => commit('REFRESH_APP'),

    controlMenu: ({commit}, payload)=> commit('SET_ACCESS_MENU',payload),
    
    async assignListAbilitation({ commit }, payload) {
      commit("SET_LIST_ABILITIES", payload);
    },
    async updateUser({ commit }, payload){
      commit('UPDATE_USER',payload)
    },      
    
  },
  modules: {
    dettes,
    comptable,
    appareils,
    maintenance,
    compentrole,
    entreprise,
    serviceaccess,
    categorie,
    ventes,
  },
});
