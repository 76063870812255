<template>
    <div>
        <div class="alert mb-3">
            <span>
                {{$t('service.client.span1')}}
            </span>
            <span>
                {{$t('service.client.span2')}}
            </span>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="register">
            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete v-model="Form.client" :items="clientList" item-value="id" item-text="tel"
                        auto-select-first chips clearable 
                        :label="$i18n.locale == 'fr' ? 'Téléphone': 'Phone'" :rules="requireRules">
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field v-model="Form.libelle" 
                    :label="$i18n.locale == 'fr' ? 'Appareil': 'Divice'" required :rules="requireRules">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field v-model="Form.panne" :rules="requireRules" 
                    :label="$i18n.locale == 'fr' ? 'Panne': 'Problem'" required>
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete v-model="Form.ticket" :items="ticketList" item-value="id" item-text="number"
                        auto-select-first chips clearable label="Ticket" :rules="requireRules" v-if="!updateOption">
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field v-if="!updateOption" v-model="Form.total_apayer" :rules="requireRules"
                        :label="$i18n.locale == 'fr' ? 'Prix a payer': 'Price to pay'" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-2">
                    <v-text-field v-if="!updateOption" v-model="Form.total_payer" :rules="requireRules"
                        :label="$i18n.locale == 'fr' ? 'Montant reçu': 'Amount received'" required>
                    </v-text-field>
                </v-col>
            </v-row>

            <div class="left">
                <v-btn :disabled="!valid" text color="success" class="mr-4" @click="register">
                    {{ $t('buttons.validate') }}
                </v-btn>

                <v-btn color="red darken-1" text class="mr-4" @click="reset">
                    {{ $t('buttons.cancel') }}
                </v-btn>
            </div>
        </v-form>
    </div>

</template>
<script>

import ApiService from '@/classes/ApiService'
import DetteManager from '@/services/dettes/DetteManager.js';
import TicketManager from '@/services/TicketManager.js'
import ClientManager from '@/services/ClientManager.js'
import { mapGetters } from "vuex";
export default {
    data: () => ({
        valid: true,//Form validation
        requireRules: [
            v => !!v || 'Ce champ est obligatoire',
        ],

        displayAlert: false,
        updateOption: false,
        items: null,
        clientList: null,
        select: null,
        // selectTicket:null,
        ticketList: null,
        displayTicketField: true,//Afficher le combo si c'est un rengistrement ou non ticket si on fait unr modification
        Form: {
            id: '',
            libelle: null,
            panne: null,
            client: null,
            ticket: true,
            total_apayer: 0,
            total_payer: 0,
        },

    }),
    props: {
        formElement: null,
        deletedAppareil: null,
    },
    created() {
        this.getClientContact()
        this.loadTicket()
    },
    computed: {
        refreshed() {
            return this.getAppReloaded()
        },
    },
    watch: {
        formElement(even) {
            if (even) {
                this.displayTicketField = false
                this.Form.libelle = even.libelle
                this.Form.ticket = even.ticket.id
                this.Form.client = even.client.id
                this.Form.panne = even.panne
                this.Form.id = even.id
            }
            this.updateOption = true
        },
        deletedAppareil() {
            this.loadTicket()
            this.$emit('TicketLoaded')
        },
        refreshed(){
            this.getClientContact()
            this.loadTicket()
        }
    },
    methods: {
        ...mapGetters(['getAccessToken','getEntreprise', 'getAppReloaded']),
        register() {
            if (this.$refs.form.validate()) {
                if (!this.updateOption) {
                    this.submit()
                } else {
                    this.update()
                }
            }
        },
        submit() {
            // this.$refs.observer.validate()
            let api = new ApiService()
            api.add("appareils/register/", this.Form, null ,this.getAccessToken())
                .then((resp) => {
                    console.log(resp.data)
                    this.loadTicket()
                    this.$store.dispatch('reloadApp')
                    this.$store.dispatch('dettes/updateListDette')
                    DetteManager.listDette(this.getEntreprise().id, this.getAccessToken())
                    this.clear()
                }).catch((error) => {
                    console.log(error)
                });
        },
        reset() {
            this.Form.libelle = ''
            this.Form.ticket = ''
            this.Form.client = ''
            this.Form.panne = ''
            this.displayTicketField = true
            this.updateOption = false
            this.$refs.form.reset()
        },
        update() {
            // this.$refs.observer.validate()
            let api = new ApiService()

            api.update("appareils/register/", this.Form, this.Form.id, this.getAccessToken())
                .then((resp) => {
                    console.log(resp.data)
                    this.clear()
                    this.$store.dispatch('reloadApp')
                    this.$emit('submit')
                }).catch((error) => {
                    let obj = error.response.data
                    Object.values(obj).forEach(val => {
                        this.items = val;
                        this.displayAlert = !this.displayAlert
                    });
                });
        },
        getClientContact() {
            ClientManager.listClient(this.getEntreprise().id, this.getAccessToken())
            .then((resp) => {
                this.clientList = resp.data
                console.log(resp.data)
                this.clear()
                this.$emit('submit')
            }).catch((error) => {
                console.log(error)
            });
        },
        loadTicket() {
            TicketManager.listTicket(this.getEntreprise().id,this.getAccessToken())
            .then((resp)=>{
                this.ticketList = resp.data
                this.clear()
                this.$emit('submit')
            }).catch((error) => {
                alert('error')
                console.log(error)
            });
        },
        clear() {
            this.Form.libelle = ''
            this.Form.ticket = ''
            this.Form.client = ''
            this.Form.panne = ''
            this.displayTicketField = true
            this.updateOption = false
            this.$refs.form.reset()
        },
    },
    

}
</script>
<style scoped lang="scss">
.alert {
    background: lightseagreen;
    padding: 7px;
    border-radius: 4px;
    color: darken($color: #000000, $amount: 1);
    text-align: justify;

    span:first-child {
        font-size: large;
        color: darkred;
        font-weight: 800;
        font-style: italic;
    }

    span:last-child {
        font-size: medium;
    }
}
</style>