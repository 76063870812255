import ApiServiceD from "@/classes/ApiServiceD";
// import store from "@/store";
export default class TicketManager{
   static listTicketInUse(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('tickets/inuse/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                resolve(rep.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    static listTicket(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('tickets/register/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                resolve(rep)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    static listTicketAvailable(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('tickets/all-ticket-available/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                resolve(rep)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    static listTicketNoneAvailable(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('tickets/all-ticket-unavailable/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                resolve(rep)
            }).catch(err=>{
                reject(err)
            })
        })
    }
    static registerTicket(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('clients/register/',formData, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static registerBrokenTicket(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('tickets/brokend-ticket/',formData,null ,token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}