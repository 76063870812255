import ApiServiceD from "@/classes/ApiServiceD";
import ApiService from "@/classes/ApiService";
import Message from "./Message";
import UserService from "./UserService"
import store from "@/store";
export default class ClientManager {
  static listClient(entreprise_id, token = null) {
    return new Promise(function (resolve, reject) {
      new ApiServiceD()
        .getList("clients/register/", { entreprise: entreprise_id }, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static generateCodeSuscription() {
    return new Promise(function (resolve, reject) {
      new ApiServiceD().axiosInstance().post("entreprise/register/", null)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  static registerEntreprise(formData, token = null) {
    return new Promise(function (resolve, reject) {
      new ApiServiceD()
        .add("clients/register/", formData, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  static updateEntreprise(formData, code_entreprise) {
    return new Promise(function (resolve, reject) {
      if (UserService.validateTelephone(formData.telephone) && UserService.validateEmail(formData.email_address)) {
        new ApiService()
          .axiosInstance().put("entreprise/update/", formData, {
            params: { codeInscription: code_entreprise },
          })
          .then((resp) => {
            let message = "Votre entreprise a été enregistrer avec succès via le code " + code_entreprise + ". Veiller ajouter un compte utilisateur"
            store.dispatch(
              "makeErros",
              Message.createMessage(
                message,
                "success"
              )
            );
            store.dispatch("entreprise/assignCodeEntreprise", code_entreprise);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log("Error: " + err);
            UserService.getErrorMessage(err);
          });
      } else {
        reject(false)
      }
    });
  }
  static deleteClient(id_client, token = null) {
    return new Promise(function (resolve, reject) {
      new ApiServiceD()
        .delete("clients/register/", { id: id_client }, token)
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
