<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-spacer></v-spacer>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Montant total de la caisse' : 'Total cash amount' }}
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Montant total de la caisse' : 'Total cash amount' }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <template v-slot:cbody>
                    <v-card-text style="color:darkslategray;font-weight: bold;">
                        <p>
                            <span class="ml-8" v-size="20">
                              {{ lang == 'fr' ? 'Solde Caisse ' : 'Cash balance' }}: 
                              {{soldeCaise |localFormat}}{{ getEntreprise().type_monaie }}
                            </span>
                        </p>
                        <br>
                        <hr>
                        <br>
                        <p>
                            <span class="ml-8" v-size="20">
                              {{ lang == 'fr' ? 'Bénéfice sur caisse ' : 'Cash earnings' }}: 
                              {{ soldeBenefice |localFormat}}{{ getEntreprise().type_monaie }}
                            </span>
                        </p>
                    </v-card-text>
                    <v-spacer></v-spacer>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import {mapGetters} from 'vuex'
export default {
    components: { FormTemplate},
    computed: {
        lang() {
            return this.$store.state.locale
        },
        soldeCaise(){
          return this.getSoldeCaisse()  
        },
        soldeBenefice(){
          return this.getSoldeBenefice()  
        }
    },
    methods: {
        ...mapGetters(['getEntreprise']),
        ...mapGetters("comptable",['getSoldeCaisse','getSoldeBenefice']),
    },
}
</script>
<style lang="">
    
</style>