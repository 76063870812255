import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginVue from "@/views/LoginVue.vue";
import AdminLogin from "@/views/AdminLogin.vue";
import AdminDashboardView from "@/views/AdminDashboardView.vue";
import RegisterVue from "@/views/RegisterView.vue";
import LogoutVue from "@/views/LogoutVue.vue";
import ServiceView from "@/views/ServiceVue.vue";
import PiecesView from "@/views/PiecesView.vue";
import StockView from "@/views/StockView";
import ComptaView from "@/views/ComptabiliteMainView.vue";
import FournisseurView from "@/views/Fournisseur";
import EntrepriseView from "@/views/EntrepriseView.vue";
import TypeSuscription from "@/views/TypeSuscription.vue";
import SettingView from "@/views/SettingView.vue";
import ProfilView from "@/views/ProfilView.vue";
import NotFound from "@/components/NotFound.vue";
import store from "@/store";

// import auth from '@/store/auth/authentication'

Vue.use(VueRouter);
const appName ="Busicompta | "
const routes = [
  { 
    path: "/", 
    name: "home", 
    component: HomeView, 
    meta: { auth: true, title: appName+"Home"} 
  },
  { 
    path: "/login", 
    name: "login", 
    component: LoginVue, 
    meta: { auth: false, title: appName+"Login" } 
  },
  { 
    path: "/admin/login", 
    name: "adminLogin", 
    component: AdminLogin, 
    meta: { auth: false, title: appName+"Admin-Login" } 
  },
  { 
    path: "/admin-dashboard", 
    name: "AdminDashboard", 
    component: AdminDashboardView, 
    meta: { auth: false, title: appName+"Admin-Dashboard" } 
  },
  {
    path: "/profil",
    name: "profilView",
    component: ProfilView,
    meta: { auth: true, title: appName+"Profile" },
  },
  {
    path: "/singin",
    name: "singin",
    component: RegisterVue,
    meta: { auth: false, title: appName+"Sing In" },
  },
  {
    path: "/logout",
    name: "LogoutVue",
    component: LogoutVue,
    meta: { auth: false, title: appName },
  },
  {
    path: "/entreprise",
    name: "EntrepriseView",
    component: EntrepriseView,
    meta: { auth: false, title: appName+"Entreprise" },
  },
  {
    path: "/type-suscription",
    name: "TypeSuscription",
    component: TypeSuscription,
    meta: { auth: false, title: appName+"Suscription Type" },
  },
  {
    path: "/admin/service/maintenance",
    name: "ServiceView",
    alias: ["/admin/service/client", "/admin/service/vente"],
    component: ServiceView,
    meta: { auth: true, title: appName+"Services" },
  },
  {
    path: "/admin/pieces/articles",
    name: "PiecesView",
    component: PiecesView,
    meta: { auth: true, title: appName+"Products" },
  },
  {
    path: "/admin/stockes/aprovisionnement",
    name: "SrockView",
    alias: ["/admin/stockes/fournisseur"],
    component: StockView,
    meta: { auth: true, title: appName+"Stock" },
  },
  {
    path: "/admin/comptabilite",
    name: "ComptaView",
    component: ComptaView,
    meta: { auth: true, title: appName+"Accounting" },
  },
  {
    path: "/fournisseur",
    name: "FournisseurView",
    component: FournisseurView,
    meta: { auth: true, title: appName+"Supplier" },
  },
  {
    path: "/setting",
    name: "SettingView",
    component: SettingView,
    meta: { auth: true, title: appName+"Setting" },
  },
  { path: "/NotFound(.*)*", component: NotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if (to.name !== "login" && 
    to.name !== "singin" && 
    to.name !== "EntrepriseView" && 
    to.name !== "TypeSuscription" &&
    to.name !== "adminLogin" &&
    to.name !== "AdminDashboard" &&
    !store.getters.isAuthenticated) {
    next({ name: "login" });
  }
  else {
    // if (store.getters.isMaster) {
    //   next();
    // } else {
    //   let path = to.path.split("/").pop();
    //   let components = store.getters.getUserComponents;
    //   if (store.getters.isAuthenticated && components.length > 0) {
    //     console.log("Les components: ", components);
    //       const found = components.find((item) => item.libelle == path);
    //       if (found) {
    //         next()
    //       }else{
    //         next({ name: "/" })
    //       }
        
    //   }
    //   else{
    //     next();
    //   }
      next();
    // }
  }
});

// https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

export default router;
