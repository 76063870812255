<template>
    <v-app style="background:#DDDDDD">
        <v-container>
            <v-alert v-if="errors.message" dense :type="errors.type" style="height: 60px; padding-to: 10px">
                {{ errors.message }}
            </v-alert>
            <v-row class="mt-2">
                <v-col cols="12" md="6" sm="12" class="mx-auto">
                    <template>
                        <v-card outlined class="p-10">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        <u><span id="textInfo" v-size="16">Modifier mon mot de passe </span></u>
                                    </div>
                                    <div>
                                        <v-form ref="form" lazy-validation @submit.prevent="register">
                                            <v-row>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.oldPassword" 
                                                        :rules="requireRules"
                                                        label="Mot de passe actuel" 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="password">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.newPassword" 
                                                        :rules="requireRules"
                                                        label="Nouveau mot de passe" 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="password">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.newPassword1" 
                                                        :rules="requireRules"
                                                        label="Confirmation" 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="password">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>

                                            <div class="left">
                                                <v-btn  text color="success" class="mr-4"
                                                    @click="resetPassword">
                                                    Validate
                                                </v-btn>

                                                <v-btn color="red darken-1" text class="mr-4" @click="clean">
                                                    Reset Form
                                                </v-btn>

                                                <v-btn text color="primary" @click="navigateTo('/')">
                                                    <v-icon size="25px" class="mr-1">fa-brands fa-accusoft</v-icon>
                                                    <span>Accueil</span>
                                                </v-btn>
                                            </div>
                                        </v-form>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>

                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Message from '@/services/Message'
import UserService from '@/services/UserService'
export default {
    components: {},
    data() {
        return {
            tab: null,
            errors: this.getErrors(),
            Form: {
                username: null,
                oldPassword: null,
                newPassword: null,
                newPassword1: null,
            },
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
        }
    },

    created() {
        this.$store.dispatch("makeErros", Message.createMessage(''))
    },
    methods: {
        ...mapGetters(['getErrors', 'getAccessToken', 'getUser']),
        clean(){
            this.Form.oldPassword =null;
            this.Form.newPassword = null;
        },

        resetPassword(){
            if(this.$refs.form.validate()){
                this.Form.username = this.getUser().username
                UserService.resetPassword(this.Form, this.getAccessToken())
            }
        },
        navigateTo(path){this.$router.push(path)}
    },
}
</script>
<style scoped>
#textInfo {
    font-style: italic;
    font-weight: 600;
    color: '#114E60';
}
</style>