<template lang="">
    <v-card  >
        <v-toolbar v-appcolor>
            <v-toolbar-title>
                Categories
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="!updateOptiion" icon fab color="white" elevation=3 title="Nouvelle categorie" @click="save">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="updateOptiion" icon fab color="white" elevation=3 title="Modifier categorie" @click="update">
                <v-icon>
                    mdi-content-save
                </v-icon>
            </v-btn>
            
        </v-toolbar>
        <div class="px-3">
            <v-text-field v-model="Form.libelle" 
            :label=" lang =='fr'? 'Nom categorie':'Category Name'" single-line hide-details
                dense class="white black--text mb-5 mt-5" size="15px">
            </v-text-field>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="white black--text mb-2" size="15px">
            </v-text-field>
        </div>
            <v-data-table 
                :headers="headers" 
                :items="items" 
                :search="search" 
                sort-by="calories" class="elevation-1" 
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                    fa-duotone fa-pen-to-square
                    </v-icon>
                    <v-icon small @click="launchDialogDeleteFormation(item)" color="red" title="Supprimer">
                    fa-duotone fa-trash-can
                    </v-icon>
                </template>
            </v-data-table>
            <!-- Delete Categori dialog -->
            <v-dialog persistent v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Cette action vous fera perdre les traces de cet article dans votre comptabilité. <br>
                    Êtez-vous vraiment sûr ?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialogDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="confirmDelete">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- End dialog -->
    </v-card>
</template>
<script>
// import ApiServiceD from '@/classes/ApiServiceD';;
import ArticleManager from '@/services/ArticleManager';
import { mapGetters } from 'vuex';
export default {
    name: 'CategorieView',
    data() {
        return {
            valid: true,
            updateOptiion: false,
            dialog: false,
            categoriID: '',
            items: [],
            search: '',
            headers: [
                {
                    text: 'Nom Categori',
                    align: 'start',
                    sortable: true,
                    value: 'libelle',
                },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
            Form: {
                libelle: '',
                entreprise: '',
            }
        }
    },
    created() {
        this.listCategory();
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },

    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        save() {
            if (this.Form.libelle.trim() != '') {
                this.Form.entreprise = this.getEntreprise().id;
                ArticleManager.addCategory(this.Form, this.getAccessToken())
                    .then((res) => {
                        this.listCategory()
                        this.Form.libelle = ''
                        console.log(res)
                    })
                    .catch((err) => console.log(err))
            }

        },
        listCategory() {
            ArticleManager.getCategories(this.getEntreprise().id, this.getAccessToken())
                .then((res) => {
                    console.log(res.data);
                    this.items = res.data;
                })
                .catch((err) => console.log(err))

        },
        editItem(item) {
            this.updateOptiion = true;
            this.Form.libelle = item.libelle;
            this.categoriID = item.id;
        },
        update() {
            this.Form.entreprise = this.getEntreprise().id;
            ArticleManager.updateCategory(this.Form, this.categoriID, this.getAccessToken())
                .then((res) => {
                    this.items.filter((item) => {
                        if (item.id == res.data.id) {
                            item.libelle = res.data.libelle
                        }
                    })
                    this.$store.dispatch('categorie/fetchListCategorie', this.items)
                    this.Form.libelle = ''
                    this.updateOptiion = false;
                    this.categoriID = '';
                    console.log(res)
                })
                .catch((err) => console.log(err))
        },
        launchDialogDeleteFormation(item) {
            this.dialog = true;
            this.categoriID = item.id;
        },
        closeDialogDelete() {
            this.dialog = false;
            this.categoriID = '';
        },
        confirmDelete() {
            this.Form.entreprise = this.getEntreprise().id;
            ArticleManager.deleteCategory(this.categoriID, this.getAccessToken())
                .then(() => {
                    let newList = [];
                    this.items.filter((item) => {
                        if (item.id != this.categoriID) {
                            newList.push(item);
                        }
                    });
                    this.items = newList;
                    this.$store.dispatch('categorie/fetchListCategorie', this.items)
                    this.closeDialogDelete()
                })
                .catch((err) => console.log(err))
        }
    },

}
</script>