<template>
  <v-container>
    <v-toolbar flat v-appcolor>
      <v-toolbar-title class="white--text" style="font-size:medium">LISTE DES FOURNISSEURS</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
        rounded class="d-none d-sm-flex white black--text" style="width:30%; max-width: 30%;">
      </v-text-field>
    </v-toolbar>
    <v-data-table :headers="headers" :items="listFournisseurs" :search="search" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-flex d-sm-none white black--text" size="15px">
          </v-text-field>
          <v-dialog persistent v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" v-bind="attrs" v-on="on">
                Nouveau Fournisseur
              </v-btn>

              <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                v-bind="attrs" v-on="on">
                <v-icon>fa-regular fa-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title v-appcolor>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="fournisseurForm.full_name" label="Prénom(s) et Nom"
                          :rules="firstNameRules" required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field :rules="lastNameRules" v-model="fournisseurForm.tel" label="Téléphone / Email"
                          required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" text color="success" class="mr-4" @click="validate">
                  Validate
                </v-btn>

                <v-btn color="red darken-1" text class="mr-4" @click="reset">
                  Reset Form
                </v-btn>

                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
          fa-duotone fa-pen-to-square
        </v-icon>
        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
          fa-duotone fa-trash-can
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import FournisseurManager from '@/services/FournisseurManager';
import { mapGetters } from "vuex";
export default {
  components: {},
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'PRENOM(S) ET NOM',
        align: 'start',
        sortable: true,
        value: 'full_name',
      },
      { text: 'CONTACTS', value: 'tel' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    listFournisseurs: [],
    editedIndex: -1,
    fournisseurForm: {
      full_name: '',
      tel: '',
      email: '',
      entreprise: '',
    },
    defaultItem: {
      full_name: '',
      tel: '',
      email: '',
      entreprise: '',
    },
    firstNameRules: [
      v => !!v || 'Ce champ est obligatoire',
    ],
    lastNameRules: [
      v => !!v || 'Ce champ est obligatoire',
    ],
    telRules: [
      v => !!v || 'Ce champ est obligatoire(un numéro valide)',
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nouvel enregistrement' : 'Modifiaction'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise']),
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          FournisseurManager.updateFournisseur(this.fournisseurForm, this.getEntreprise().id,
            this.fournisseurForm.id, this.getAccessToken())
            .then((rep) => {
              console.log(rep)
              this.close()
              this.reset()
              this.initialize()
            }).catch((error) => { console.log(error.response.data) })
          // this.close()
        }
        else {
          let rep = this.checkDuplicateFullName(this.fournisseurForm.full_name)

          if (parseInt(rep) == -1) {
            let rep = this.checkDuplicateAdresse(this.fournisseurForm.tel)
            if (parseInt(rep) == -1) {
              this.fournisseurForm.entreprise = this.getEntreprise().id;
              FournisseurManager.registerFournisseur(this.fournisseurForm, this.getAccessToken())
                .then((rep) => {
                  console.log(rep)
                  this.close()
                  this.reset()
                  this.successMessage('Enregistrement effectué avec succès')
                  this.initialize()
                }).catch((error) => {
                  error.response.data.forEach(el => {
                    console.log(el)
                  });
                })
              this.close()
            } else {
              this.errorMessage('Un fournisseur avec cet adresse existe déjà')
            }

          } else {
            this.errorMessage('Ce Fournisseur existe déjà')
          }

        }
        this.initialize()
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    initialize() {
      FournisseurManager.listFournisseur(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.listFournisseurs = rep.data
        }).catch((error) => {
          console.log(error)
        })
    },
    deleteItemConfirm() {
      FournisseurManager.deleteFournisseur(this.fournisseurForm.id ,this.getAccessToken())
      .then((rep) => {
          console.log(rep)
          this.initialize()
        }).catch((error) => { console.log(error.response.data) })
      this.closeDelete()
    },
    editItem(item) {
      this.editedIndex = this.listFournisseurs.indexOf(item)
      this.fournisseurForm = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.listFournisseurs.indexOf(item)
      this.fournisseurForm = Object.assign({}, item)
      this.dialogDelete = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.fournisseurForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.reset()
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.fournisseurForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkDuplicateFullName(value) {
      return this.listFournisseurs.findIndex(el => el.full_name == value)
    },
    checkDuplicateAdresse(value) {
      return this.listFournisseurs.findIndex(el => el.tel == value)
    },
    errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    successMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
  },
}
</script>