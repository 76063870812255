<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="900px" hide-overlay>
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5 white--text">
                        {{ lang =='fr'? 'Historique des approvisionnements':'Supply history'}}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="detailHeaders" :items="listeDetaill" class="elevation-1">
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    {{ lang =='fr'? 'COUT TOTAL':'TOTAL COST' }}: {{ totalCout | localFormat }}{{ getEntreprise().type_monaie }}
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="dialog = false">{{ $t('buttons.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "DialogMaintenace",
    components: {},
    data(){
        return {
            dialog: false,
            totalCout: 0,
            dateAchat: null,
            listeDetaill: [],
            detailHeaders: this.$i18n.locale == 'fr' ?[
                { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle'},
                { text: 'PRIX ACHAT UNITAIRE', value: 'pau' },
                { text: 'QUANTITE ', value: 'qantite_apro' },
                { text: 'DATE ACHAT ', value: 'date_appro' },

            ]
            :[
                { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle'},
                { text: 'UNIT PURCHASE PRICE', value: 'pau' },
                { text: 'QUANTITY ', value: 'qantite_apro' },
                { text: 'PURCHASE DATE ', value: 'date_appro' },
            ],
        }
    },
    props: {
        lounchDetail: null,
        detailInfo: null,
    },
    watch: {
        /**
         * a wather to lounch the dialog
         */
        lounchDetail() {
            this.dialog = true;
        },

        detailInfo(data) {
            this.listeDetaill = data
            data.forEach(el => {
                this.totalCout += (parseInt(el.qantite_apro) * parseFloat(el.pau))
                console.log("Les prix de ventes: ", el.pvu)
            });
            this.dialog = true
        }

    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getEntreprise']),
    }

}
</script>

<style>
.text-info {
    color: darkslategray;
    font-size: 16px;
}
</style>