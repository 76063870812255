<template>
    <v-app style="background:#DDDDDD">
        <SideBare />
        <ToolBare>
            <template v-slot:cbody>
                <SettingChips v-on:linkCliked="makeLinke()" />
            </template>
        </ToolBare>
        <component v-bind:is="cmp"></component>
    </v-app>
</template>
<script>
import SideBare from "../components/SideBare.vue";
import ToolBare from "@/components/ToolBare.vue";
import SettingChips from '@/components/SettingChips.vue'
import SettingComponentVue from "@/components/SettingComponentVue.vue";
export default {
    name: "SettingView",
    data: () => ({
        cmp: '',
    }),
    components: {
        SideBare,
        ToolBare,
        SettingChips,
        "setting":SettingComponentVue,
    },
    methods: {
       makeLinke() {
            this.cmp = window.location.pathname.split('/').pop()
        },
    },
    created(){
        this.makeLinke()
    }
}
</script>
<style>
</style>