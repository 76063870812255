<template>
    <div>
        <v-divider></v-divider>
        <v-tabs app v-model="tab" height="50px" color="seagreen"  centered icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" >
               {{ lang == "fr" ? "Nouvelle Vente" : "New Sale" }} 
                <v-icon>mdi-cellphone-cog</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                {{ lang == "fr" ? "Vente du jour" : "Sale of the day" }}
                <v-icon>mdi-clipboard-edit-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-body">
           <v-tab-item  :value="'tab-1'">
                <v-card flat id="tab-body">
                    <NouvellVente />
                </v-card>
            </v-tab-item>
            <v-tab-item  :value="'tab-2'">
                <v-card flat id="tab-body" >
                    <ListeVente />
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import NouvellVente from "./NouvellVente.vue";
import ListeVente from "./ListeVente.vue";

export default {
    name: "ClientVue",
    components: {NouvellVente, ListeVente },
    data() {
        return {
            tab: null,
        };
    }, 
    computed:{
        lang(){
            return this.$store.state.locale
        }
    },
}
</script>

<style scoped>
#tab-body{
    background-color: #DDDDDD;
    border-radius: 0px;
}
</style>