import ApiServiceD from "@/classes/ApiServiceD";

export default class ApprovisonnementManager{
    static listApproDetail(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('approvisionnement/detail/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static listAppro(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('approvisionnement/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static addApprovisonnement(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('approvisionnement/register/',formData, null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    // Cette methode est faite pour de maintenace qui à son sein le client
    // n'envoi pas ses articles dans des conteneur donc la methode enregistre
    // un approvisionnement sant tenier compte du numéro de conteneur.
    static approvisionner(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('approvisionnement/approvisionner/',formData, null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static deleteApprovisonnement(id_appro, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().delete('approvisionnement/register/',{id: id_appro}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}