<template>
    <v-container fluid>
        <v-tabs app v-model="tab" height="50px"  centered icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" >
                Liste des entreprises
                <v-icon>fas fa-hospital</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                Liste des clients
                <v-icon>mdi-clipboard-edit-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab">
        
           <v-tab-item  :value="'tab-1'">
                <v-card flat>
                    <ListeEntrepriseView />
                </v-card>
            </v-tab-item>

            <!-- <v-tab-item  :value="'tab-2'">
                <v-card flat id="tab-body" >
                    <ListClientView />
                </v-card>
            </v-tab-item> -->

        </v-tabs-items>
    </v-container>
</template>

<script>
import ListeEntrepriseView from "@/components/Admin/ListeEntrepriseView.vue"
export default {
    name: "ClientVue",
    data() {
        return {
            tab: null,
        };
    },
    components: { ListeEntrepriseView }
}
</script>

<style scoped>
#tab-body{
    background-color: #DDDDDD;
    border-radius: 0px;
}
</style>