<template>
    <div>
        <v-divider></v-divider>
        <v-tabs app v-model="tab" height="50px" color="seagreen"  centered icons-and-text>
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1" >
                {{$t('service.client.listAppariel')}}
                <v-icon>mdi-cellphone-cog</v-icon>
            </v-tab>

            <v-tab href="#tab-2">
                {{$t('service.client.listClient')}}
                <v-icon>mdi-clipboard-edit-outline</v-icon>
            </v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-body">
        
           <v-tab-item  :value="'tab-1'">
                <v-card flat id="tab-body">
                    <AppareilList />
                </v-card>
            </v-tab-item>

            <v-tab-item  :value="'tab-2'">
                <v-card flat id="tab-body" >
                    <ListClientView />
                </v-card>
            </v-tab-item>

        </v-tabs-items>
    </div>
</template>

<script>
import ListClientView from "../Client/ListClientView.vue";
import AppareilList from "./AppareilList.vue";
export default {
    name: "ClientVue",
    data() {
        return {
            tab: null,
        };
    },
    components: { ListClientView, AppareilList }
}
</script>

<style scoped>
#tab-body{
    background-color: #DDDDDD;
    border-radius: 0px;
}
</style>