<template>
    <v-dialog persistent v-model="dialog" max-width="700px" hide-overlay>
        <v-card>
            <v-card-title v-appcolor>
                <span class="text-h5 white--text">
                    {{ $i18n.locale == 'fr'? "Nouvel enregistrement":"New recording" }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col col="12">
                            <DatePiker @selectedDate="getSelectedDate" />
                        </v-col>
                    </v-row>
                    <v-switch v-model="toggleSwitch" :label="$t('service.maintenance.registerWithPiece')" color="#114E60" hide-details>
                    </v-switch>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                            <v-col cols="12" md="6" sm="6">
                                <v-autocomplete v-model="formMaintenance.ticket" :items="ticketList" item-value="id"
                                    item-text="number" auto-select-first chips clearable label="Ticket"
                                    :rules="requireRule" @change="selectTicke" required>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6" sm="6" v-if="toggleSwitch">
                                <v-autocomplete v-model="formMaintenance.pieces" :items="pieceList" item-value="id"
                                    multiple item-text="libelle" auto-select-first chips clearable :label="$t('service.maintenance.libellePiece')"
                                    hide-selected hide-no-data :rules="requireRule" @change="setSelectPiece" required>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6" sm="6">
                                <v-subheader style="font-size:16px; color:">{{ $t('service.maintenance.totalToPay') }}</v-subheader>
                                <v-subheader style="color:black">{{ total_apayer | localFormat }}{{ getEntreprise().type_monaie }}
                                </v-subheader>
                            </v-col>

                            <v-col cols="12" md="6" sm="6" v-if="toggleSwitch">
                                <v-subheader style="font-size:16px; color:">{{ $t('service.maintenance.piecePrice') }}</v-subheader>
                                <v-subheader style="color:black">{{ formMaintenance.prix_pieces | localFormat }}{{ getEntreprise().type_monaie }}
                                </v-subheader>
                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                                <v-subheader style="font-size:16px;">{{ $t('service.maintenance.totalPaid') }}</v-subheader>
                                <v-subheader class="black--text">{{ total_payer | localFormat }}{{ getEntreprise().type_monaie }}
                                </v-subheader>
                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                                <v-subheader style="font-size:16px;">{{ $t('service.maintenance.workforce') }}</v-subheader>
                                <v-subheader :class="maineouvre < 0 ? 'red--text' : 'black--text'">{{ maineouvre |
                                    localFormat
                                }}{{ getEntreprise().type_monaie }}
                                </v-subheader>
                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                                <v-text-field v-model="formMaintenance.receiving_amount" :rules="requireRule"
                                    :label="$t('service.maintenance.receivedAMount')"
                                     required
                                    @keyup="calulateRemainPrice(formMaintenance.receiving_amount)">
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" sm="6">
                                <v-subheader style="font-size:16px; color:">
                                    {{ $i18n.locale == "fr" ? "Reste a payer":"Remains to pay" }}
                                </v-subheader>
                                <v-subheader style="color:black">{{ rest_apayer | localFormat }}{{ getEntreprise().type_monaie }}
                                </v-subheader>
                            </v-col>
                        </v-row>

                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="left">
                    <v-btn :disabled="!valid" text color="success" class="mr-4" @click="register">
                        {{ $t('buttons.validate') }}
                    </v-btn>

                    <v-btn color="red darken-1" text class="mr-4" @click="reset">
                        {{ $t('buttons.cancel') }}
                    </v-btn>
                    <v-btn color="red darken-1" text class="mr-4" @click="close">
                        {{ $t('buttons.close') }}
                    </v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import DatePiker from './DatePiker.vue';
import DetteManager from '@/services/dettes/DetteManager.js';
import MaintenaceManager from '@/services/MaintenanceManager.js';
import TicketManager from '@/services/TicketManager.js';
import AppareilManager from '@/services/AppareilManager.js';
import ArticleManager from '@/services/ArticleManager';
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "DialogMaintenace",
    components: { DatePiker },
    data: () => ({
        valid: true,
        dialog: false,
        ticketList: [],
        pieceList: [],
        toggleSwitch: true,
        selectedTicket: false,
        total_apayer: 0,
        total_payer: 0,
        rest_apayer: 0,
        maineouvre: 0,

        formMaintenance: {
            ticket: '',
            pieces: '',
            prix_pieces: 0,
            receiving_amount: '',
            registred_at: '',
        },

        requireRule: [
            v => !!v || "Ce champ est obligatoire",
        ],
    }),
    props: {
        lounchDialog: null
    },
    watch: {
        /**
         * a wather to lounch the dialog
         */
        lounchDialog() {
            this.dialog = true;
        },
        toggleSwitch() {
            this.reinisilize()
        },
        selectedTicket() {
            this.total_apayer = parseInt(this.getAppreil().total_apayer);
            this.total_payer = parseInt(this.getAppreil().total_payer);
            this.rest_apayer = parseInt(this.getAppreil().rest_apayer);
            this.receiving_amount = 0
            if (!isNaN(this.total_apayer) && this.total_apayer != '') {
                this.calculatePrice(this.total_apayer)
            }
        }

    },
    created() {
        this.loadTicket();
        this.loadPiece();
        this.loadAppareil()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        ...mapGetters("appareils", ['getListAppareil', 'getAppreil']),
        ...mapActions("appareils", ['filterAppareil']),

        loadTicket() {
            TicketManager.listTicketInUse(this.getEntreprise().id, this.getAccessToken())
                .then((resp) => {
                    console.log("ListTicketInUse: ",resp);
                    this.ticketList = resp
                }).catch((error) => {
                    console.log(error)
                });
        },
        loadPiece() {
            ArticleManager.listAvailable(this.getEntreprise().id, this.getAccessToken())
                .then((resp) => {
                    this.pieceList = resp.data;
                }).catch((error) => {
                    console.log(error);
                });
        },
        loadAppareil() {
            AppareilManager.listUnfixedAppareil(this.getEntreprise().id, this.getAccessToken())
                .then((resp) => {
                    console.log("List unfixed appareil: ",resp.data);
                }).catch((error) => {
                    console.log(error)
                })
        },
        register() {
            console.log(this.formMaintenance)
            if (this.$refs.form.validate() == true) {
                MaintenaceManager.newMaintenance(this.formMaintenance, this.getAccessToken())
                    .then(() => {
                        this.loadTicket();
                        this.$emit("submit")
                        this.maineouvre = 0
                        this.reset()
                        DetteManager.listDette(this.getEntreprise().id,this.getAccessToken())
                        this.successMessage("Enregistrement effectué avec succès.")
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }

        },
        getSelectedDate(value) {
            console.log(value)
            this.formMaintenance.registred_at = value
        },
        calculatePrice(val) {
            if (!isNaN(val) && val != '') {
                let mount = (parseFloat(val) - parseFloat(this.formMaintenance.prix_pieces))
                this.maineouvre = mount > 0 ? mount : 0;
            } else
                this.maineouvre = 0
        },
        calulateRemainPrice(val) {
            let somme = parseFloat(this.total_payer) + parseFloat(val)
            if (!isNaN(val) && val != '') {
                if ((parseFloat(this.total_apayer) > somme))
                    this.rest_apayer = (parseFloat(this.total_apayer) - (somme))
                else
                    this.rest_apayer = 0
            } else
                this.rest_apayer = (parseFloat(this.total_apayer) - (parseFloat(this.total_payer)))
        },
        // One of a wery usefull method in piece selection
        setSelectPiece(array) {
            // get the last id in the list
            let id = array.slice(-1)
            this.receiving_amount = 0
            // fetche the corresponding element in the list
            this.pieceList.forEach(el => {
                if (id > 0) {
                    if (el.id == id) {
                        this.formMaintenance.prix_pieces += parseInt(el.pvu)
                        if (!isNaN(this.total_apayer) && this.total_apayer != '') {
                            this.calculatePrice(this.total_apayer)
                        }
                    }
                } else {
                    this.reinisilize()
                }

            });

        },
        selectTicke(value) {
            if (value) {
                this.filterAppareil(value)
                this.selectedTicket = !this.selectedTicket
            } else {
                this.total_apayer = 0
                this.total_payer = 0
                this.rest_apayer = 0
                this.maineouvre = 0
            }
        },
        reinisilize() {
            this.formMaintenance.prix_pieces = 0
            this.formMaintenance.pieces = null
            this.maineouvre = 0
            this.total_apayer = 0
            this.total_payer = 0
            this.rest_apayer = 0
        },
        reset() {
            this.$refs.form.reset()
            this.reinisilize()
        },
        close() {
            this.dialog = false
            this.$refs.form.reset()
            this.reinisilize()
        },
        successMessage(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                timeout: 4095,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
    },

}
</script>

<style>

</style>